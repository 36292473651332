"use client";

import { useEffect, useState } from "react";
import { Button } from "antd";
import MenuCustom from "../components/menu";
import { Dropdown, Space } from "antd";
import { CircleTick } from "../svg/circle";
import DropdownCustom from "../components/dropdown";
import ServiceCenter from "../components/serviceCenter";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TermAndCondition from "../components/termAndCondition";
import arrow from "../Assest/org-arrow.svg";
import DocumentMeta from "react-document-meta";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-9XTPFNX5EE"; // your Measurement ID

const meta = {
  title: "Steallr M22光子嫩膚 - Lumenis香港",
  description:
    "M22採用全球唯一Stellar OPT™平頂波專利技術，可以選擇配備強效脈衝光IPL及ResurFX™分段式激光機頭 ，9塊專業濾片波長廣及400至1200nm，獲美國FDA及歐盟CE認證，90%以上皮膚問題均可精準治理，無創護膚，治療期及復原期均極短。",
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: "utf-8",
    name: {
      keywords: "react,meta,document,html,tags, Stellar",
    },
  },
};

const items = [
  {
    label: "Steallr M22光子嫩膚技術",
    key: "Item-1",
  },
  {
    label: "IPL強效脈衝光技術",
    key: "Item-2",
  },
  {
    label: "9大濾片",
    key: "Item-3",
  },
  {
    label: "ResurFX™無創分段煥膚激光",
    key: "Item-4",
  },
  {
    label: "成果見證",
    key: "Item-5",
  },
  {
    label: "FAQ",
    key: "Item-6",
  },
  // {
  //   label: "實證分享",
  //   key: "Item-7",
  // },
  {
    label: "尋找服務中心",
    key: "Item-8",
  },
];

const imageItems = [
  {
    label: "/images/p3-caseStudy/p3_img7.png",
    key: "Item-1",
  },
  {
    label: "Splendor X脫毛技術比較",
    key: "Item-2",
  },
  {
    label: "FAQ",
    key: "Item-3",
  },
  // {
  //   label: "實證分享",
  //   key: "Item-4",
  // },
  {
    label: "尋找服務中心",
    key: "Item-5",
  },
];

const caseStudyImageMin = 17;
const caseStudyImageMax = 24;

function M22Page() {
  const [item, setItem] = useState("Item-1");
  const [caseStudyImageNum, setCaseStudyImageNum] = useState(17);

  const [cardImage, setCardImage] = useState("/images/p3-card/p3_img1.png");
  const [cardImageNum, setCardImageNum] = useState(1);

  useEffect(() => {
    if (cardImageNum === 0) {
      setCardImageNum(1);
    }
    if (cardImageNum === 3) {
      setCardImageNum(2);
    }
    setCardImage(`/images/p3-card/p3_img${cardImageNum}`);
  }, [cardImageNum, cardImage]);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/m22", title: "m22" });
  }, [])

  const onClick = ({ key }) => {
    if (key === "Item-1") {
      document.getElementById("item1").scrollIntoView();
    }
    if (key === "Item-2") {
      document.getElementById("item2").scrollIntoView();
    }
    if (key === "Item-3") {
      document.getElementById("item3").scrollIntoView();
    }
    if (key === "Item-5") {
      document.getElementById("item5").scrollIntoView();
    }

    if (key === "Item-4") {
      document.getElementById("ResurFX").scrollIntoView();
    }

    if (key === "Item-6") {
      document.getElementById("item6").scrollIntoView();
    }

    if (key === "Item-8") {
      document.getElementById("item8").scrollIntoView();
    }
    // setItem(key);
  };

  const onClickCaseStudyImage = () => {};

  // const onClickCaseStudyImage = (num) => {
  //   setCaseStudyImageNum(caseStudyImageNum + num);
  //   console.log("caseStudyImageNum + num", caseStudyImageNum + num)
  //   console.log("/images/p3-caseStudy/p3_img${caseStudyImageNum}.png", `/images/p3-caseStudy/p3_img${caseStudyImageNum + num}.png`)
  //   setCaseStudyImage(`/images/p3-caseStudy/p3_img${caseStudyImageNum}.png`);
  // }

  return (
    <DocumentMeta {...meta}>
      <div
        style={{ color: "#333333", fontFamily: "Noto Sans HK" }}
        className="[&>*]:w-full w-full"
      >
        <Header />
        <div className="w-full">
          <picture className="w-full object-cover">
            <source media="(min-width:1024px)" srcSet="/images/p3_logo_3.png" />
            <source media="(min-width:768px)" srcSet="/images/p3_logo_2.png" />
            <img src="/images/p3_logo.png" alt="" />
          </picture>
        </div>
        <Dropdown
          trigger={["click"]}
          menu={{ items, onClick }}
          className="px-4 flex justify-between h-16 !text-xl font-semibold md:!hidden"
        >
          <Space>
            Steallr M22光子嫩膚技術
            <img
              src={arrow}
              alt="Banner"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            {/* <UpDownArrow /> */}
          </Space>
        </Dropdown>
        <MenuCustom
          className={"hidden md:flex font-semibold text-xl"}
          currentItemKey={item}
          items={items}
          onClick={onClick}
        />
        <div id="item1"></div>

        <div className="p-4 md:px-8 lg:px-[10%] [&>*]:text-center [&>*]:tracking-wide [&>*]:mt-10">
          <h2 className="text-2xl font-medium" style={{ color: "#F87C56" }}>
            全球唯一專利光學技術
            <br />
            一機包辦還原無瑕肌
          </h2>
          <p>
            全新醫療級Stellar光子嫩膚不同問題一機包辦，一次療程可掃除肌底造成光老化雜質，還原肌膚透明感，展現年輕，健康的活力膚質。
          </p>
          <p>
            強效脈衝光IPL機頭採用全球唯一OPT™和MSP™專利技術，有效改善光老化嫩膚，暗瘡，玫瑰痤瘡，曬斑、荷爾蒙斑、老人斑、雀斑、微血管擴張、痘疤紅印、皮膚反黑等等。解決皮膚光老化問題逾90%*，改善暗瘡達8成**！
          </p>
          <br />
        </div>
        <div className="mt-10 md:mt-5 flex justify-center">
          <img
            src="/images/p3_img0.png"
            className="w-full md:max-w-[600px]"
            alt=""
          />
        </div>
        <div id="item2"></div>
        <div
          className="mt-10 md:mt-16 lg:mt-20 pb-10 flex flex-col items-center"
          style={{ background: "linear-gradient(#FFA171BB, #F58A2520)" }}
        >
          <picture className="object-cover">
            <source media="(min-width:1024px)" srcSet="/images/p3_img1-3.png" />
            <source media="(min-width:768px)" srcSet="/images/p3_img1-2.png" />
            <img src="/images/p3_img1-1.png" alt="" />
          </picture>
          <picture className="block object-cover mt-10 lg:mt-20 w-10/12 md:w-4/5 lg:w-2/3">
            <source media="(min-width:1024px)" srcSet={`${cardImage}-3.png`} />
            <source media="(min-width:768px)" srcSet={`${cardImage}-2.png`} />
            <img src={`${cardImage}-1.png`} alt="" />
          </picture>
        </div>

        <div className="mt-5 pr-8">
          <Button
            onClick={() => setCardImageNum(cardImageNum + 1)}
            style={{ float: "right" }}
            danger
            shape="circle"
            icon={">"}
          />
          <Button
            onClick={() => setCardImageNum(cardImageNum - 1)}
            style={{ float: "right", marginRight: 5 }}
            danger
            shape="circle"
            icon={"<"}
          />
        </div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28">
          <div style={{ color: "#777777" }} className="text-sm mb-1">
            Technology Features
          </div>
          <div style={{ color: "#F87C56" }} className="text-2xl font-bold mb-8">
            IPL強效脈衝機頭
          </div>
          <div>
            <h3 className="text-xl font-medium mt-10 md:mt-16 lg:mt-20">
              全球唯一OPT™平頂波專利技術
            </h3>
            <div className="mt-5 flex justify-center flex-col items-center md:items-start md:flex-row md:justify-between md:[&>*]:w-[48%]">
              <img
                src="/images/p3_img2.png"
                alt=""
                className="w-full max-w-[480px]"
              />
              <div className="mt-4">
                <p className="mb-8">
                  Stellar OPT™平頂波專利技術(Optimal Pulse Technology)
                  能自動去除過多或過少能量，令療程更有效安全。
                  <br />
                  <br />
                  舊式脈衝光普遍因能量不均容易影響治療，可能引致表皮燙傷及反黑。
                  <br />
                </p>
                <div className="[&>div]:grid [&>div]:grid-cols-10 [&>div>div]:col-span-9 [&>div]:mt-2">
                  <div>
                    <CircleTick />
                    <div>能量一致，令療程更有效果安全</div>
                  </div>
                  <div>
                    <CircleTick />
                    <div>以不同波長應對各種問題</div>
                  </div>
                  <div>
                    <CircleTick />
                    <div>
                      撃退各層黑色素、曬斑、荷爾蒙斑、老人斑、雀斑、微血管擴張、痘疤紅印等瑕疵。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 md:mt-16 lg:mt-20">
            <h3 className="text-xl font-medium">MSP多重連續脈衝科技</h3>
            <div className="mt-5 flex justify-center flex-col items-center md:items-start md:flex-row md:justify-between md:[&>*]:w-[48%]">
              <img
                src="/images/p3_img3.png"
                alt=""
                className="w-full max-w-[480px]"
              />
              <div className="mt-4">
                <div className="text-lg mb-5">
                  容許單一脈衝能量轉換成雙脈衝或三脈衝
                </div>
                <div className="[&>div]:grid [&>div]:grid-cols-10 [&>div>div]:col-span-9 [&>div]:mt-2">
                  <div>
                    <CircleTick />
                    <div>能量一致，令療程更有效果安全</div>
                  </div>
                  <div>
                    <CircleTick />
                    <div>以不同波長應對各種問題</div>
                  </div>
                  <div>
                    <CircleTick />
                    <div>
                      撃退各層黑色素、曬斑、荷爾蒙斑、老人斑、雀斑、微血管擴張、痘疤紅印等瑕疵。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="item3"></div>
          <div className="mt-10 md:mt-16 lg:mt-20">
            <h3 className="w-full text-xl text-wrap font-semibold [word-spacing:9999px] lg:[word-spacing:4px] lg:text-center">
              9種ExpertFilters™濾片 針對性解決各種問題
            </h3>
            <div className="mt-4 lg:mt-8 overflow-x-scroll customize-scrollbar rounded-xl">
              <img
                src="/images/p3_img4.png"
                alt=""
                className="block max-w-[48rem] max-h-none lg:max-w-none lg:w-full"
              />
            </div>
            <div style={{ fontSize: "8px", color: "#999999" }}>
              *以上資料僅供參考，所有療程以醫生最終判斷為準。
            </div>
          </div>
        </div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28">
          <div style={{ color: "#777777" }} className="text-sm mb-1">
            Technology Comparison
          </div>
          <div style={{ color: "#F87C56" }} className="text-2xl font-bold mb-5">
            市面光學療程比較
          </div>
          <div className="overflow-x-scroll customize-scrollbar">
            <table className="text-left">
              <thead className="[&>tr>th]:p-6 [&>tr>th]:font-semibold">
                <tr>
                  <th style={{ color: "#F87C56" }}>
                    Stellar M22 強效脈衝光技術
                  </th>
                  <th>其他彩光</th>
                  <th>一般皮秒激光</th>
                </tr>
              </thead>
              <tbody className="[&>tr>th]:p-6 [&>tr>td]:p-6 [&>tr>th]:min-w-64 [&>tr>td]:min-w-64 [&>tr>td]:align-top">
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#F87C56" }}
                >
                  <td colSpan={3} className="!py-1 !px-6">
                    光譜
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    波長由400nm至1200nm 能夠達至更深皮層，解決底層問題
                  </th>
                  <td>
                    大部份由400nm至900nm 光譜闊度過窄，未能處理深層皮膚問題
                  </td>
                  <td>
                    通常分532nm、755nm及1064nm
                    單一波長，未能同時處理各種皮膚問題
                  </td>
                </tr>
                <tr className="bg-orange-400 text-white">
                  <td
                    colSpan={3}
                    className="!py-1 !px-6"
                    style={{ backgroundColor: "#F87C56" }}
                  >
                    能量輸出
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    OPT®專利技術能量輸出一致而穩定。
                    連續脈衝科技，為周圍與表皮組織提供冷卻時間。
                  </th>
                  <td>有機會能量輸出不均及不穩定</td>
                  <td>單一脈衝，能量集中，刺激較大</td>
                </tr>
                <tr>
                  <td>
                    <img src="/images/p3_img5.png" alt="" />
                  </td>
                  <td>
                    <img src="/images/p3_img6.png" alt="" />
                  </td>
                  <td>
                    <img src="/images/p3_img7.png" alt="" />
                  </td>
                </tr>
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#F87C56" }}
                >
                  <td colSpan={3} className="!py-1 !px-6">
                    治療範圍
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    波長範圍極廣，配搭9款不同濾片及2款機頭，能針對性解決各種問題，包括紅血絲、色素、暗沉、過度活躍的油脂
                    設有AOPT模式，提供專屬定製療程。
                  </th>
                  <td>波長範圍有限，不能解決深層次問題</td>
                  <td>比較單一，未能提供客製化治療方案</td>
                </tr>
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#F87C56" }}
                >
                  <td colSpan={3} className="!py-1 !px-6">
                    痛感
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    具備CoolScan™冷卻掃描系統，將痛感及傷害減至最低
                  </th>
                  <td>缺乏MSP和冰感探頭技術，假設同一能量下，痛感可能較大。</td>
                  <td>因為能量聚焦，痛感相對較大</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="p-6 md:px-10 lg:px-[10%] text-center mt-8"
          style={{
            background: "linear-gradient(#F58A2555, #F5A37F55 70%, #FE968F55)",
          }}
        >
          <div className="text-[#777777] text-sm text-left mb-2">
            3 Promises
          </div>
          <img
            src="/images/p3_img8.png"
            alt=""
            className="w-[6rem] md:w-[8rem] z-10"
          />
          <div className="my-4 flex flex-row flex-wrap justify-around [&>div]:mt-4 [&>div]:w-[34%] md:[&>div]:w-[26%] [&>div>div]:[word-spacing:9999px] [&>div>div]:mt-4">
            <div>
              <img src="/images/p3_img9.png" alt="" />
              <div>波長選擇多變 療效更精準顯著</div>
            </div>
            <div>
              <img src="/images/p3_img10.png" alt="" />
              <div>具備冷卻系統 避免灼傷且痛感極低</div>
            </div>
            <div>
              <img src="/images/p3_img11.png" alt="" />
              <div>無創護膚 治療期及復原期均極短</div>
            </div>
          </div>
        </div>
        <div id="ResurFX"></div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28">
          <div style={{ color: "#777777" }} className="text-sm">
            Technology Features
          </div>
          <div className="flex flex-col md:flex-row-reverse md:gap-[10%] items-center mt-4">
            <img
              src="/images/p3_img12.svg"
              alt=""
              className="w-full md:w-auto h-auto max-w-96"
            />
            <div className="mt-4">
              <h3 style={{ color: "#F87C56" }} className="text-3xl font-bold">
                ResurFX™無創分段煥膚激光
              </h3>
              <p className="my-4 font-light">
                ResurFX™屬非創傷性分段式激光煥膚技術，能將Erbium Glass
                1565nm激光能量穿透真皮層。
              </p>
              <div className="font-light [&>div]:grid [&>div]:grid-cols-10 [&>div>div]:col-span-9 [&>div]:mt-2">
                <div>
                  <CircleTick />
                  <div>刺激皮膚膠原蛋白增生</div>
                </div>
                <div>
                  <CircleTick />
                  <div>修復及填補凹陷肌膚</div>
                </div>
                <div>
                  <CircleTick />
                  <div>強效解決毛孔粗大、凹凸洞、皺紋、妊娠紋、疤痕等問題</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-[10%] items-center mt-4">
            <img
              src="/images/p3_img13.png"
              alt=""
              className="w-[90%] max-w-64 md:w-auto h-auto rounded-full"
            />
            <div>
              <div className="font-bold text-lg mt-4">
                非順序掃描技術 (Non Sequential Pulsing Technology)
              </div>
              <p className="font-light mt-4">
                ResurFX™配備先進掃描儀，可選擇不同點陣形狀、大小和密度的光束，從超過600種組合中選取最佳治療方案。具有先進CoolScan™冷卻掃描系統，有效控制每個分段式光點，保護皮膚免受高能量傷害，確保安全並減低不適痛感。
              </p>
            </div>
          </div>
        </div>
        <div id="item4"></div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28">
          <div style={{ color: "#777777" }} className="text-sm mb-1">
            Technology Comparison
          </div>
          <div style={{ color: "#F87C56" }} className="text-2xl font-bold mb-5">
            激光煥膚技術比較
          </div>
          <div className="overflow-x-scroll customize-scrollbar border-b-2 border-b-orange-400">
            <table className="text-left">
              <thead className="[&>tr>th]:p-6 [&>tr>th]:font-semibold">
                <tr>
                  <th style={{ color: "#F87C56" }}>ResurFX™</th>
                  <th>Pico點陣激光</th>
                  <th>Co2點陣激光</th>
                </tr>
              </thead>
              <tbody className="[&>tr>th]:p-6 [&>tr>td]:p-6 [&>tr>th]:min-w-64 [&>tr>td]:min-w-64">
                <tr
                  className="text-white"
                  style={{ backgroundColor: "#E8835F" }}
                >
                  <td colSpan={3} className="!py-1 !px-6 font-bold">
                    能量輸出
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    超過600種形狀、大小和密度組合，能應對不同皮膚位置及問題
                  </th>
                  <td>單一聚焦光點，光點可調大小，但形狀不變</td>
                  <td>點陣式輸出，能量極大，必須由醫生操作</td>
                </tr>
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#E8835F" }}
                >
                  <td colSpan={3} className="!py-1 !px-6 font-bold">
                    波長原理
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    非入侵性除疤波長1565nm，能量被皮膚水份吸收，刺激膠原生長，撫平疤痕
                  </th>
                  <td>
                    1064nm，能量被皮膚中的黑色素和水份分散，效能不夠集中，效果一般
                    {/* 非入侵性除疤波長1565nm，能量被皮膚水份吸收，刺激膠原生長，撫平疤痕 */}
                  </td>
                  <td>入侵性除疤波長10600nm ，有汽化、凝結、收縮的效果</td>
                </tr>
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#E8835F" }}
                >
                  <td colSpan={3} className="!py-1 !px-6 font-bold">
                    入侵性質
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">非入侵性</th>
                  <td>非入侵性</td>
                  <td>入侵性</td>
                </tr>
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#E8835F" }}
                >
                  <td colSpan={3} className="!py-1 !px-6 font-bold">
                    安全／痛感
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    具備CoolScan™冷卻掃描系統，將痛感及傷害減至最低
                  </th>
                  <td>相對安全，但痛感明顯</td>
                  <td>因極高能量，痛感較大，護理不當容易反黑</td>
                </tr>
                <tr
                  className="bg-orange-400 text-white"
                  style={{ backgroundColor: "#E8835F" }}
                >
                  <td colSpan={3} className="!py-1 !px-6 font-bold">
                    復原期
                  </td>
                </tr>
                <tr>
                  <th className="font-medium">
                    完成療程後會輕微泛紅，待一天後會慢慢減退，復原期極短 1-3 日
                  </th>
                  <td>1-3 日，可能會有起痧現象，則需要較長康復期</td>
                  <td>復原期長達7-10天，會結焦及泛紅</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="p-6 md:px-10 lg:px-[10%] text-center mt-8 pb-8"
          style={{
            background: "linear-gradient(#F58A2555, #F5A37F55 70%, #FE968F55)",
          }}
        >
          <div className="text-[#777777] text-sm text-left mb-2">
            3 Promises
          </div>
          <img
            src="/images/p3_img14.png"
            alt=""
            className="w-[6rem] md:w-[8rem] z-10"
          />
          <div className="my-4 flex flex-row flex-wrap justify-around text-lg font-medium [&>div]:mt-4 [&>div]:w-[34%] md:[&>div]:w-[26%] [&>div>div]:[word-spacing:9999px] [&>div>div]:mt-4 lg:[&>div]:mt-8">
            <div>
              <img src="/images/p3_img15.png" alt="" />
              <div>非入侵性除疤波 長達1565nm</div>
            </div>
            <div>
              <img src="/images/p3_img16.png" alt="" />
              <div>光束極細密 防止遺漏</div>
            </div>
            <div>
              <img src="/images/p3_img17.png" alt="" />
              <div>深度達1mm 有效深層治療</div>
            </div>
            <div>
              <img src="/images/p3_img18.png" alt="" />
              <div>具CoolScan系統 避免熱傷害</div>
            </div>
            <div>
              <img src="/images/p3_img19.png" alt="" />
              <div className="![word-spacing:4px]">極速改善 毛孔細紋</div>
            </div>
            <div>
              <img src="/images/p3_img20.png" alt="" />
              <div>刺激膠原再生 高效去疤</div>
            </div>
          </div>
        </div>

        <div id="item5"></div>
        <div
          style={{
            background: "linear-gradient(#F09D90, #FFB69F)",
          }}
          className="px-6 md:px-10 lg:px-[10%] text-center w-full rounded-3xl py-20 -translate-y-4"
        >
          <div className="text-white tracking-wider text-sm">CASE STUDY</div>
          <h3 className="text-white font-medium text-3xl">成效見證</h3>
          <div className="w-full h-96 flex justify-between mt-8">
            <img
              src={`images/p3-caseStudy/p3_img${caseStudyImageNum}.png`}
              alt=""
              className="w-full md:w-[30%] object-cover"
            />
            <img
              src={`images/p3-caseStudy/p3_img${
                (caseStudyImageNum + 1) % caseStudyImageMax
              }.png`}
              alt=""
              className="w-full md:w-[30%] hidden object-cover md:block"
            />
            <img
              src={`images/p3-caseStudy/p3_img${caseStudyImageNum + 2}.png`}
              alt=""
              className="w-full md:w-[30%] hidden object-cover md:block"
            />
          </div>
        </div>
        <div className="hidden md:block pr-8">
          <Button
            onClick={() =>
              setCaseStudyImageNum((c) =>
                Math.min(caseStudyImageMax - 2, c + 1)
              )
            }
            style={{ float: "right" }}
            danger
            shape="circle"
            icon={">"}
          />
          <Button
            onClick={() =>
              setCaseStudyImageNum((c) => Math.max(caseStudyImageMin, c - 1))
            }
            style={{ float: "right", marginRight: 5 }}
            danger
            shape="circle"
            icon={"<"}
          />
        </div>
        <div className="md:hidden pr-8">
          <Button
            onClick={() =>
              setCaseStudyImageNum((c) =>
                Math.min(caseStudyImageMax, c + 1)
              )
            }
            style={{ float: "right" }}
            danger
            shape="circle"
            icon={">"}
          />
          <Button
            onClick={() =>
              setCaseStudyImageNum((c) => Math.max(caseStudyImageMin, c - 1))
            }
            style={{ float: "right", marginRight: 5 }}
            danger
            shape="circle"
            icon={"<"}
          />
        </div>
        <div id="item6"></div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28 [&>hr]:my-4 [&>div>.expand-head>.revert]:fill-orange-400">
          <h3 className="font-bold text-3xl mb-5" style={{ color: "#F87C56" }}>
            FAQ
          </h3>
          <DropdownCustom title={"1. Stellar採用了哪些頂尖技術？"}>
            Stellar採用全球唯一OPT™平頂波專利技術（Optimal Pulse
            Technology）和MSP™多重連續脈衝技術
            ，能提供安全舒適的全面客製化方案。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"2. 與其他類似療程相比，M22 Stellar有哪些優越之處？"}
          >
            比起其他光學療程，Stellar覆蓋範圍特別廣闊達400至1200nm，更有MSP™技術，量膚定製，研究發現能解決由90%*以上光老化皮膚問題。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"3. Stellar安全嗎？會否為皮膚帶來創傷？"}>
            「OPT™平頂波專利技術」和MSP™多重連續脈衝技術，
            比起舊式脈衝光輸出的能量更穩定，調教至適合亞洲人皮膚的治療模式，避免表皮燙傷及反黑，療程有效安全。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"4. IPL機頭和ResurFX™機頭分別應在甚麼時候使用？"}
          >
            IPL機頭主治黑色素、曬斑、荷爾蒙斑、老人斑、雀斑、微血管擴張、暗瘡、痘疤紅印等瑕疵；ResurFX™機頭則適合處理毛孔粗大、凹凸洞、皺紋、妊娠紋、疤痕等問題。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"5. 治療過程有沒有痛感？"}>
            Stellar具備SapphireCool™/CoolScan™冷卻掃描系統，使用時近乎沒有痛感、相當舒適，只有輕微刺麻感覺。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"6. ResurFX療程後皮膚會出現起痧現象嗎？有沒有修復期？"}
          >
            療程後皮膚不會出現起痧現象，復原期極短只有1至3日。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"7. 孕婦或敏感肌能使用嗎？"}>
            不建議孕婦使用光學療程；而敏感肌情況因人而異，則建議經專業治療師判斷並使用。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"8. 治療後有甚麼注意事項？"}>
            做好保濕防曬即可，治療當天避免運動，治療後一週內盡量避免使用過熱的水洗臉，避免進行去角質或美白等較為刺激的保養流程。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"9. IPL強效脈衝光做完會立即見效嗎？整個療程會建議做多少次？"}
          >
            通常一次IPL治療已有明顯透亮效果，3次療程後達致更佳效果*。建議每月固定保養一次。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"10. 建議相隔多久做一次療程？"}>
            建議每月固定保養一次，令效果更加持久。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={
              "11. 如最近做了其他高能量療程，須相隔多久才可接受 Stellar療程？"
            }
          >
            建議諮詢醫生，如接受了其他高能量療程，建議相隔三至四星期才進行M22
            Stellar療程。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"12. 除了用在面部，其他身體部位也能使用嗎？"}>
            除了用在面部，也能用於其他身體部位，解決不同皮膚問題，詳情請諮詢醫生。
          </DropdownCustom>
          <hr />
          <div className="my-8" style={{ fontSize: "8px", color: "#999999" }}>
            ^指以IPL技術而言。
            <br />
            <br />
            * Chen Ping, Du Xueliang, Li Yongxuan, Deng Lin, Liu Bilai, Lin
            Shaoming & Michael H. Gold (2016) A retrospective study on the
            clinical efficacy of the intense pulsed light source for photodamage
            and skin rejuvenation, Journal of Cosmetic and Laser Therapy, 18:4,
            217-224, DOI: 10.3109/14764172.2015
            <br />
            <br />
            **Knight JM. Combined 400-600nm and 800-1200nm Intense Pulsed
            Phototherapy of Facial Acne Vulgaris. J Drugs Dermatol. 2019 Nov
            1;18(11):1116-1122. PMID: 31741354.
          </div>
        </div>
        <div id="item8"></div>
        <ServiceCenter />

        <TermAndCondition />
        <Footer />
      </div>
    </DocumentMeta>
  );
}

export default M22Page;
