import { Breadcrumb, Layout, Menu, Button, theme, Row, Col, Drawer, Image, Anchor, Link, Grid, Divider, Card } from 'antd';
import navLogo from "../../Assest/nav-logo.svg";
import globeLogo from "../../Assest/globe.svg";
import { YoutubeOutlined, InstagramOutlined, FacebookOutlined } from '@ant-design/icons';
import youtubeLogo from "../../Assest/youtube.svg";
import igLogo from "../../Assest/ig.svg";
import facebookLogo from "../../Assest/facebook.svg";



const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

function AppFooter() {
  const screens = useBreakpoint();
  return (
    <Footer
      style={{
        // textAlign: 'center',
        backgroundColor: "#282828"

      }}
    >
      <Row gutter={[16, 16]} style={{ minHeight: 200 }}>
        <Col xs={0} sm={0} md={6} >

          <Image
            style={{ color: "white", marginLeft: 10 }}
            width={screens.lg ? 200 : ""}
            src={navLogo}
          />
        </Col>

        <Col xs={24} sm={24} md={0} >
          <Image
            // style={{ color: "white", padding: "0 10px" }}
            src={navLogo}
          />
        </Col>

        <Col xs={24} sm={24} md={6}>
          <div style={{ padding: "10px 0px", textAlign: 'left', color: "#999999", fontSize: 16 }}><a href="/m22" >Stellar M22™</a></div>
          <div style={{ padding: "10px 0px", textAlign: 'left', color: "#999999", fontSize: 16 }}><a href="/stellar" >SPLENDOR X</a></div>
        </Col>

        <Col xs={24} sm={24} md={6} style={{ padding: 0 }}>
          <Card style={{backgroundColor: "#353535", borderColor: "#353535"}}>
            <Row>
              <Col xs={24} sm={24}>
                <span style={{color: "#FFFFFF"}}>客户服務熱線</span>
              </Col>

              <Col xs={24} sm={24}>
                <span style={{color: "#999999"}}>(852) 2174 2800</span>
              </Col>

              <Col xs={24} sm={24} style={{marginTop: 10}}>
                <Row gutter={[8, 16]}>
                  <Col xs={12} sm={12}>
                    <p style={{color: "#FFFFFF"}}>傳真</p>
                    <p style={{color: "#999999"}}>+852 2722 5151</p>
                  </Col>

                  <Col xs={12} sm={12}>
                    <Col xs={24} sm={24}>
                      <p style={{color: "#FFFFFF"}}>與我們聯繫</p>
                    </Col>
                    <Col xs={24} sm={24}>
                      <Row>
                        <Col xs={8} sm={8}>
                          <Image
                            onClick={()=> window.location.href="https://www.youtube.com/@LumenisAesthetics"}
                            preview={false}
                            style={{ paddingTop: 6 }}
                            src={youtubeLogo}
                          />
                        </Col>

                        <Col xs={8} sm={8}>
                          <Image
                            onClick={()=> window.location.href="https://www.instagram.com/lumenis.hk/"}
                            preview={false}
                            style={{ paddingTop: 6 }}
                            src={igLogo}
                          />
                        </Col>

                        <Col xs={8} sm={8}>
                          <Image
                            onClick={()=> window.location.href="https://m.facebook.com/profile.php?id=100084659576967"}
                            preview={false}
                            style={{ paddingTop: 6 }}
                            src={facebookLogo}
                          />
                        </Col>
                      </Row>


                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Row>
            <Col xs={2} sm={2} >
              <Image
                style={{ paddingTop: 14 }}
                src={globeLogo}
              />
            </Col>
            <Col xs={20} sm={20} style={{marginTop: 10 }}>
              <p style={{ fontSize: 14, textAlign: 'left', color: 'white' }}> <span>中文</span> | <span onClick={()=> window.location.href="https://lumenis.com/"}>English</span></p>
            </Col>
          </Row>
          <Divider style={{ padding: 0, background: '#2f3030' }} />
        </Col>

        {/* <Col xs={24} sm={24} md={24}>
          <div style={{ padding: "10px 0px", color: "#777777", textAlign: 'left' }}>條款及細則</div>
        </Col> */}

        <Col xs={24} sm={24} md={24}>
          <div style={{ padding: "10px 0px", color: "#777777", textAlign: 'left' }}><a href="/privatePolicy">私隱政策</a></div>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <div style={{ pdding: "10px 0px", color: "#777777", textAlign: 'left' }}>©2024 Lumenis Be (HK) Limited. <br/> 版權所有，不得轉載</div>
        </Col>
      </Row>
    </Footer>
  );
}
export default AppFooter;
