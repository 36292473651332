"use client";

import { LeftArrow, RightArrow } from "../svg/arrow";
import { Phone } from "../svg/phone";
import { useEffect, useState } from "react";
import _ from "lodash";

const serviceCenterList = {
  hongKongIsland: {
    total: 76,
    list: [
      {
        product: "splendorX",
        logo: "sample-logo.png",
        id: 2,
        district: "香港島",
        name: "Splendor X - 智賢醫學及皮膚激光中心 (中環) ",
        phone: "6161 1223 ",
        address: "中環租庇利街1號喜訊大廈11樓1103室",
      },
      {
        product: "splendorX",
        logo: "sample-logo.png",
        id: 2,
        district: "香港島",
        name: "Splendor X - Modelage Aesthetic Centre",
        phone: "6319 1584 ",
        address: "銅鑼灣世貿中心36樓01室",
      },
      {
        product: "splendorX",
        logo: "Selene-Beauty.jpg",
        id: 2,
        district: "香港島",
        name: "Splendor X - Selene Beauty ",
        phone: "6600 8996",
        address: "銅鑼灣登龍街景隆大廈12樓整層",
      },
      {
        product: "splendorX",
        logo: "You-Beauty-CWB.jpg",
        id: 2,
        district: "香港島",
        name: "Splendor X - You Beauty (銅鑼灣) ",
        phone: "2664 7005",
        address: "銅鑼灣告士打道280號世界貿易中心15樓1501-1503室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 2,
        district: "香港島",
        name: "Medicskin",
        phone: "2526 9333",
        address: "香港中環新世界大廈 2 期 21樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 3,
        district: "香港島",
        name: "Sphere Medical Aesthetic Centre",
        phone: "2911 0022",
        address: "香港中環砵典乍街十號四樓全層",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 7,
        district: "香港島",
        name: "RENAISSAGE BEAUTY INSTITUTE",
        phone: "5577 6223",
        address: "中環皇后大道中陸佑行304",
      },
      {
        product: "m22",
        logo: "cosderma.png",
        id: 8,
        district: "香港島",
        name: "Cosderma",
        phone: "3188 0478",
        address: "香港銅鑼灣禮頓道77號禮頓中心8樓820室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 10,
        district: "香港島",
        name: "Vitae By CosMax (Central)",
        phone: "3929 1888",
        address: "皇后大道中16-18號新世界大廈2座11樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 11,
        district: "香港島",
        name: "Vitae (CWB)",
        phone: "3929 1838",
        address: "香港銅鑼灣羅素街38號金朝陽中心22樓",
      },
      {
        product: "m22",
        logo: "Hong-Kong-Hearts.png",
        id: 13,
        district: "香港島",
        name: "Hong Kong Hearts",
        phone: "3589 6758",
        address: "尖沙咀廣東道海港城海洋中心15樓1510-1512A室",
      },
      {
        product: "m22",
        logo: "HEAL_Aesthetic_Standard.png",
        id: 14,
        district: "香港島",
        name: "Heal (Aesthetic)",
        phone: "",
        address: "中環華懋中心2期30樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 16,
        district: "香港島",
        name: "Be Me Aesthetic",
        phone: "2508 7962",
        address: "銅鑼灣恩平道42號亨利中心21樓",
      },
      {
        product: "m22",
        logo: "Life-Young.png",
        id: 17,
        district: "香港島",
        name: "Life Young -Central",
        phone: "9699 1312",
        address: "中環雪廠街16號西洋會所大廈8樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 22,
        district: "香港島",
        name: "Modelage",
        phone: "3188 3158",
        address: "銅鑼灣世貿中心36樓01室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 24,
        district: "香港島",
        name: "Ever Beauty Aesthetic & Laser",
        phone: "2537 3928",
        address: "香港中環德輔道中71號永安集團大廈5字樓510室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 25,
        district: "香港島",
        name: "Nume (CWB)",
        phone: "2295 0988",
        address: "銅鑼灣告士打道255 - 257號信和廣場25樓2505室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 29,
        district: "香港島",
        name: "Beaunique Medical Aesthetics",
        phone: "6298 3288",
        address: "銅鑼灣告士打道280號02-03室世界貿易中心20樓",
      },
      {
        product: "m22",
        logo: "natural-house-beauty.png",
        id: 30,
        district: "香港島",
        name: "Natural House Beauty Center",
        phone: "2788 3133",
        address: "銅鑼灣京都商業中心15樓全層",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 31,
        district: "香港島",
        name: "Medicglow",
        phone: "2522 2205",
        address: "銅鑼灣霎東街11-13號The Sharp17樓A室",
      },
      {
        product: "m22",
        logo: "skin-and-co.jpg",
        id: 32,
        district: "香港島",
        name: "Skin & Co.",
        phone: "2178 3033",
        address: "香港銅鑼灣告士打道280號世貿中心",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 33,
        district: "香港島",
        name: "PerFace (CWB)",
        phone: "6618 5618",
        address: "世貿中心35樓3501-03",
      },
      {
        product: "",
        logo: "sample-logo.png",
        id: 34,
        district: "香港島",
        name: "Queen Mary Hospital",
        phone: "2255 3838",
        address: "薄扶林薄扶林道瑪麗醫院正院",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 38,
        district: "香港島",
        name: "The Skin Space",
        phone: "6311 8869",
        address: "12/F, C Wisdom Centre, 35-37 Hollywood Rd, Central",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 40,
        district: "香港島",
        name: "Lumi Skincare",
        phone: "6288 1555",
        address: "銅鑼灣謝斐道482號兆安廣場19樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 42,
        district: "香港島",
        name: "Alluna Medical",
        phone: "9858 6625",
        address: "香港銅鑼灣百德新街2-20號恒隆中心20樓2015A室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 45,
        district: "香港島",
        name: "Perfect Medical (CWB)",
        phone: "2782 0550",
        address: "銅鑼灣禮頓山道禮頓山1座時代廣場28樓2801室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 47,
        district: "香港島",
        name: "Miioskin (Central)",
        phone: "2658 5113",
        address: "中環蘭桂坊1號2-3樓、13樓全層",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 57,
        district: "香港島",
        name: "EvrBeauty Medical Aesthetics (CWB)",
        phone: "6657 2212",
        address: "香港銅鑼灣百德新街2-20號恆隆中心18樓01-02室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 59,
        district: "香港島",
        name: "JuveMedico Centre Co. Limited",
        phone: "2882 6889",
        address: "香港皇后大道中152-156號皇后大道中心5樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 60,
        district: "香港島",
        name: "Beauskin Med Group (CWB)",
        phone: "3188 5638",
        address: "銅鑼灣告士打道280號世界貿易中心3001-3003, 30/F",
      },
      {
        product: "m22",
        logo: "Skin Origin.jpeg",
        id: 68,
        district: "香港島",
        name: "Skin Origin",
        phone: "9222 0053",
        address: "銅鑼灣登龍街1號金朝陽中心二期MIDTOWN 19樓B室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 70,
        district: "香港島",
        name: "Surrface Beauty & Medical",
        phone: "2381 3388",
        address: "銅鑼灣霎東街11號The Sharp8樓全層",
      },
      {
        product: "m22",
        logo: "Beverly.jpg",
        id: 72,
        district: "香港島",
        name: "Beverly Skin Refining Center",
        phone: "2117 0046",
        address: "銅鑼灣軒尼詩道489號銅鑼灣廣場一期20樓03A室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 74,
        district: "香港島",
        name: "MIIO MEDICAL",
        phone: "9088 6476",
        address: "銅鑼灣恆隆中心2103-06",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 33,
        district: "香港島",
        name: "Queen Mary Hospital",
        phone: "2255 3838",
        address: " 薄扶林薄扶林道瑪麗醫院正院",
      },
    ],
  },
  kowloon: {
    total: 50,
    list: [
      {
        product: "splendorX",
        logo: "You-Beauty-MK.jpg",
        name: "Splendor X - You Beauty (旺角)",
        phone: "2664 7199",
        address: "旺角亞皆老街8號朗豪坊辦公大樓10樓03-08室",
      },
      {
        product: "splendorX",
        logo: "Astra-Pro-medical.jpeg",
        name: "Splendor X - Astra Pro Medical Clinic ",
        phone: "2699 9311",
        address: "尖沙咀廣東道12號松鳳商業大廈17樓全層",
      },
      {
        product: "splendorX",
        logo: "sample-logo.png",
        name: "Splendor X - 0915 Aesthetic Clinic",
        phone: "5911 0915",
        address: "尖沙咀加連威老道愛賓商業大廈701",
      },
      {
        product: "splendorX",
        logo: "Lastkool.png",
        name: "Splendor X - LaserKool 男士激光脫毛及美容",
        phone: "2369 5990",
        address: "尖沙咀金馬倫道23號 金馬倫廣場 5A-C",
      },
      {
        product: "m22",
        logo: "Phiderma.png",
        name: "Phiderma Aesthetics Centre",
        phone: "2368 9887",
        address: "香港尖沙咀加連威老道2-6號愛賓商業大廈8樓",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Qualia Medical Spa",
        phone: "3596 7378",
        address: "尖沙咀廣東道82號84 18樓",
      },
      {
        product: "m22",
        logo: "hong-kong-skin-centre.png",
        name: "Med-thical Healthcare Laser and Skin Centre",
        phone: "6774 8178",
        address: "尖沙咀彌敦道132號美麗華廣場A座9樓912室",
      },
      {
        product: "m22",
        logo: "CUTIS.png",
        name: "Cutis",
        phone: "2332 8751",
        address: "九龍旺角彌敦道664號惠豐中心18樓全層",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "RENAISSAGE BEAUTY INSTITUTE",
        phone: "5577 6223",
        address: "中環皇后大道中陸佑行304",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Cosderma",
        phone: "3188 0478",
        address: "香港銅鑼灣禮頓道77號禮頓中心8樓820室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Vitae (TST)",
        phone: "3929 1838",
        address: "尖沙咀中間道18號半島辦公大樓16樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Vitae By CosMax (Central)",
        phone: "3929 1888",
        address: "皇后大道中16-18號新世界大廈2座11樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Vitae (CWB)",
        phone: "3929 1838",
        address: "香港銅鑼灣羅素街38號金朝陽中心22樓",
      },
      {
        product: "",
        logo: "LuxMed.png",
        name: "LUXMED (MACAU)",
        phone: "852 3589 6758",
        address: "澳門南灣湖景大馬路財神商業中心19樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Hong Kong Hearts",
        phone: "3589 6758",
        address: "尖沙咀廣東道海港城海洋中心15樓1510-1512A室",
      },
      {
        product: "",
        logo: "HEAL_Aesthetic_Standard.png",
        name: "Heal (Aesthetic)",
        phone: "",
        address: "中環華懋中心2期30樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Life Medical Centre",
        phone: "2835 8199",
        address: "澳門南灣大馬路599號羅德禮商業大廈8樓B室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Be Me Aesthetic",
        phone: "2508 7962",
        address: "銅鑼灣恩平道42號亨利中心21樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Life Young -Central",
        phone: "9699 1312",
        address: "中環雪廠街16號西洋會所大廈8樓",
      },
      {
        product: "m22",
        logo: "Anova.jpg",
        name: "Anova Medical Esthetic",
        phone: "3421 0491",
        address: "旺角彌敦道677號恆生旺角大廈19樓",
      },
      {
        product: "m22",
        logo: "Go-Young.png",
        name: "Go Young Skinlab Medical Beauty",
        phone: "9456 5841",
        address: "旺角彌敦道610號荷李活商業中心13樓1302室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Life young (TST)",
        phone: "9699 1312",
        address: "尖沙咀海港城世界商業中心9樓911-914室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Modelage",
        phone: "3188 3158",
        address: "銅鑼灣世貿中心36樓01室",
      },
      {
        product: "m22",
        logo: "All-Beauty.jpg",
        name: "All Beauty Medical Centre",
        phone: "2788 4998",
        address: "油麻地彌敦道238號1101室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Ever Beauty Aesthetic & Laser",
        phone: "2537 3928",
        address: "香港中環德輔道中71號永安集團大廈5字樓510室",
      },
      {
        product: "",
        logo: "Nume.png",
        name: "Nume (CWB)",
        phone: "2295 0988",
        address: "銅鑼灣告士打道255 - 257號信和廣場25樓2505室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Nume (TST)",
        phone: "2722 5922",
        address: "尖沙咀彌敦道132號一期 26樓2601室美麗華廣場",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Pean Beauty",
        phone: "2887 3299",
        address: "香港尖沙咀廣東道33號中港城第一座8樓801C室",
      },
      {
        product: "m22",
        logo: "beaunique.png",
        name: "Beaunique Medical Aesthetics",
        phone: "6298 3288",
        address: "旺角登打士街56號家樂坊1111室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Beaunique Medical Aesthetics",
        phone: "6298 3288",
        address: "銅鑼灣告士打道280號02-03室世界貿易中心20樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Natural House Beauty Center",
        phone: "2788 3133",
        address: "銅鑼灣京都商業中心15樓全層",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Medicglow",
        phone: "2522 2205",
        address: "銅鑼灣霎東街11-13號The Sharp17樓A室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Skin & Co.",
        phone: "2178 3033",
        address: "香港銅鑼灣告士打道280號世貿中心",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "PerFace (CWB)",
        phone: "6618 5618",
        address: "世貿中心35樓3501-03",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Queen Mary Hospital",
        phone: "2255 3838",
        address: "薄扶林薄扶林道瑪麗醫院正院",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Face Skin",
        phone: "3188 4717",
        address: "香港九龍尖沙咀天文台道8號903室",
      },
      {
        product: "m22",
        logo: "Medskin.png",
        name: "Medskin",
        phone: "2808 0889",
        address: "尖沙咀彌敦道132號美麗華大廈一期6樓617室",
      },
      {
        product: "m22",
        logo: "Lambre.png",
        name: "Lambre Health",
        phone: "9711 4220",
        address: "尖沙咀彌敦道132號美麗華廣場一期19樓1917室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "The Skin Space",
        phone: "6311 8869",
        address: "12/F, C Wisdom Centre, 35-37 Hollywood Rd, Central",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Angel Concept",
        phone: "6288 1555",
        address: "尖沙咀加拿分道18號恒生尖沙咀大廈1301室",
      },
      {
        product: "m22",
        logo: "MirrorSkin.png",
        name: "Mirror Skin Management Centre",
        phone: "6137 9228",
        address: "尖沙咀漆咸道南57-59號金馬倫中心10/F",
      },
      {
        product: "",
        logo: "Alluna.png",
        name: "Alluna Medical",
        phone: "9858 6625",
        address: "香港銅鑼灣百德新街2-20號恒隆中心20樓2015A室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Vita Medical Center",
        phone: "",
        address: "澳門南灣大馬路815號才能商業中心",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Perfect Medical (MK)",
        phone: "2782 0550",
        address: "旺角亞皆老街8號朗豪坊辦公大樓43樓 4301室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Perfect Medical (CWB)",
        phone: "2782 0550",
        address: "銅鑼灣禮頓山道禮頓山1座時代廣場28樓2801室",
      },
      {
        product: "m22",
        logo: "Miioskin.png",
        name: "Miioskin (TST)",
        phone: "2658 5313",
        address: "尖沙咀海港城世界商業中心1008室",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Miioskin (Central)",
        phone: "2658 5113",
        address: "中環蘭桂坊1號2-3樓、13樓全層",
      },
      {
        product: "",
        logo: "loveloves_Beauty.png",
        name: "Love Loves Beauty",
        phone: "6560 1218",
        address: "旺角西洋菜南街168號168大廈10樓1003室",
      },
      {
        product: "",
        logo: "BEAUSKIN.png",
        name: "Beauskin Medical Group Ltd",
        phone: "3188 5638",
        address: "銅鑼灣告士打道280號世界貿易中心3001-3003, 30/F",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Beauty Ease",
        phone: "2411 4881",
        address: "荃灣青山公路181-183號 2/F",
      },
      {
        product: "m22",
        logo: "Gardenia-Medical.png",
        name: "Gardenia Medical Limited",
        phone: "2388 9996",
        address: "香港九龍尖沙咀彌敦道132號美麗華廣場A座7樓7O7室",
      },
      {
        product: "m22",
        logo: "Aesme.jpg",
        name: "Aesme",
        phone: "9806 2023",
        address: "九龍尖沙咀漢口道28號亞太中心308-9",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "Hong Kong Skin Center",
        phone: "2376 2533",
        address: "尖沙咀彌敦道132號美麗華廣場A座17樓1717-18室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "JM Plus Clinic",
        phone: "3565 4920",
        address: "尖沙咀金馬倫道18-20號(The Mangan) 萬勤商業大廈8樓B室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "EvrBeauty Medical Aesthetics (TST)",
        phone: "6365 7694",
        address: "美麗華廣場一期6樓618室彌敦道132號",
      },
      {
        product: "m22",
        logo: "King-qualia-medical.png",
        name: "King Qualia Medical (TST)",
        phone: "3596 7378",
        address: "尖沙咀廣東道82-84流尚坊18樓",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "JuveMedico Centre Co. Limited",
        phone: "2882 6889",
        address: "香港皇后大道中152-156號皇后大道中心5樓",
      },
      {
        product: "m22",
        logo: "Dear-Beauty.png",
        name: "Dear Beauty",
        phone: "5916 6616",
        address: "香港尖沙咀分店廣東道10-12號松鳳商業大廈15/F全層",
      },
      {
        product: "m22",
        logo: "Sammy-Beauty.png",
        name: "Sammy Beauty",
        phone: "2724 8777",
        address: "香港九龍尖沙咀金馬倫道5號太興廣場12樓全層",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "RenMed Medical Beauty Lounge",
        phone: "5703 3267",
        address: "九龍尖沙咀廣東道12號松鳳商業大廈12 樓全層",
      },
      {
        product: "m22",
        logo: "LHY-beaute-lab.png",
        name: "Stellar M22 - LHY Beaute Lab Co Limited",
        phone: "6507 5791",
        address: "尖沙咀樂道27號樂輝商業大廈8樓全層",
      },
      {
        product: "",
        logo: "sample-logo.png",
        name: "Glowin' Beaute",
        phone: "5548 3509",
        address: "尖沙咀彌敦道132號美麗華廣場A座616室",
      },
      {
        product: "m22",
        logo: "Vinca-Beauty.jpg",
        name: "Vinca Beauty",
        phone: "5166 6252",
        address: "尖沙咀加拿分道20號加拿芬廣場1601室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        name: "EvrBeauty Medical Aesthetics (MK)",
        phone: "5985 8428",
        address: "旺角亞皆老街 8 號朗豪坊辦公大樓40 樓 4001 室",
      },
      {
        product: "m22",
        logo: "geellab.png",
        name: "GeelStore",
        phone: "2633 8908",
        address: "旺角登打士街 56 號家樂坊 1314 - 1317 室",
      },
    ],
  },
  newTerritories: {
    total: 4,
    list: [
      {
        product: "splendorx",
        logo: "Selene-Beauty.jpg",
        id: 1,
        district: "新界",
        name: "Splendor X - You Beauty Shatin",
        phone: "2664 7068",
        address: "大圍車公廟路18號419-420號鋪圍方4樓",
      },
      {
        product: "splendorX",
        logo: "sample-logo.png",
        id: 1,
        district: "新界",
        name: "Splendor X - 雅麗氏何妙齡那打素醫院",
        phone: "2689 2000",
        address: "大埔全安路11號",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 1,
        district: "新界",
        name: "Beauty Ease",
        phone: "2411 4881",
        address: "荃灣青山公路181-183號 2/F",
      },
      {
        product: "m22",
        logo: "Bonnie.jpeg",
        id: 2,
        district: "新界",
        name: "Bonnie Beauty",
        phone: "9248 3663",
        address: "元朗俊賢坊28號安基大廈地下4號舖",
      },
    ],
  },
  macao: {
    total: 4,
    list: [
      {
        product: "m22",
        id: 1,
        district: "澳門",
        name: "Stellar M22 - Life Medical Centre",
        phone: "2835 8199",
        address: "澳門南灣大馬路599號羅德禮商業大廈8樓B室",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 2,
        district: "澳門",
        name: "Stellar M22 - OMyGirl",
        phone: "6299 8331",
        address: "澳門渡船街14F號",
      },
      {
        product: "m22",
        logo: "sample-logo.png",
        id: 3,
        district: "澳門",
        name: "Stellar M22 - OMyGirl",
        phone: "6538 8338",
        address: "澳門氹仔廣東大馬路515號, 星皓廣場, 二層 218號舖",
      },
      {
        product: "m22",
        logo: "W-Royal-Beauty.png",
        id: 4,
        district: "澳門",
        name: "Stellar M22 - W Royal Beauty ",
        phone: "6300 1239",
        address: "澳門南灣巷雅苑閣10號ABC地下",
      },
    ],
  },
};
// const serviceCenterList = {
//   hongKongIsland: {
//     total: 73,
//     list: [
//       {
//         "id": 2,
//         "district": "香港島",
//         "name": "Medicskin",
//         "phone": "2526 9333",
//         "address": "香港中環新世界大廈 2 期 21樓"
//       },
//       {
//         "id": 3,
//         "district": "香港島",
//         "name": "Sphere Medical Aesthetic Centre",
//         "phone": "2911 0022",
//         "address": "香港中環砵典乍街十號四樓全層"
//       },
//       {
//         "id": 7,
//         "district": "香港島",
//         "name": "RENAISSAGE BEAUTY INSTITUTE",
//         "phone": "5577 6223",
//         "address": "中環皇后大道中陸佑行304"
//       },
//       {
//         "id": 8,
//         "district": "香港島",
//         "name": "Cosderma",
//         "phone": "3188 0478",
//         "address": "香港銅鑼灣禮頓道77號禮頓中心8樓820室"
//       },
//       {
//         "id": 10,
//         "district": "香港島",
//         "name": "Vitae By CosMax (Central)",
//         "phone": "3929 1888",
//         "address": "皇后大道中16-18號新世界大廈2座11樓"
//       },
//       {
//         "id": 11,
//         "district": "香港島",
//         "name": "Vitae (CWB)",
//         "phone": "3929 1838",
//         "address": "香港銅鑼灣羅素街38號金朝陽中心22樓"
//       },
//       {
//         "id": 13,
//         "district": "香港島",
//         "name": "Hong Kong Hearts",
//         "phone": "3589 6758",
//         "address": "尖沙咀廣東道海港城海洋中心15樓1510-1512A室"
//       },
//       {
//         "id": 14,
//         "district": "香港島",
//         "name": "Heal (Aesthetic)",
//         "phone": "",
//         "address": "中環華懋中心2期30樓"
//       },
//       {
//         "id": 16,
//         "district": "香港島",
//         "name": "Be Me Aesthetic",
//         "phone": "2508 7962",
//         "address": "銅鑼灣恩平道42號亨利中心21樓"
//       },
//       {
//         "id": 17,
//         "district": "香港島",
//         "name": "Life Young -Central",
//         "phone": "9699 1312",
//         "address": "中環雪廠街16號西洋會所大廈8樓"
//       },
//       {
//         "id": 22,
//         "district": "香港島",
//         "name": "Modelage",
//         "phone": "3188 3158",
//         "address": "銅鑼灣世貿中心36樓01室"
//       },
//       {
//         "id": 24,
//         "district": "香港島",
//         "name": "Ever Beauty Aesthetic & Laser",
//         "phone": "2537 3928",
//         "address": "香港中環德輔道中71號永安集團大廈5字樓510室"
//       },
//       {
//         "id": 25,
//         "district": "香港島",
//         "name": "Nume (CWB)",
//         "phone": "2295 0988",
//         "address": "銅鑼灣告士打道255 - 257號信和廣場25樓2505室"
//       },
//       {
//         "id": 29,
//         "district": "香港島",
//         "name": "Beaunique Medical Aesthetics",
//         "phone": "6298 3288",
//         "address": "銅鑼灣告士打道280號02-03室世界貿易中心20樓"
//       },
//       {
//         "id": 30,
//         "district": "香港島",
//         "name": "Natural House Beauty Center",
//         "phone": "2788 3133",
//         "address": "銅鑼灣京都商業中心15樓全層"
//       },
//       {
//         "id": 31,
//         "district": "香港島",
//         "name": "Medicglow",
//         "phone": "2522 2205",
//         "address": "銅鑼灣霎東街11-13號The Sharp17樓A室"
//       },
//       {
//         "id": 32,
//         "district": "香港島",
//         "name": "Skin & Co.",
//         "phone": "2178 3033",
//         "address": "香港銅鑼灣告士打道280號世貿中心"
//       },
//       {
//         "id": 33,
//         "district": "香港島",
//         "name": "PerFace (CWB)",
//         "phone": "6618 5618",
//         "address": "世貿中心35樓3501-03"
//       },
//       {
//         "id": 34,
//         "district": "香港島",
//         "name": "Queen Mary Hospital",
//         "phone": "2255 3838",
//         "address": "薄扶林薄扶林道瑪麗醫院正院"
//       },
//       {
//         "id": 38,
//         "district": "香港島",
//         "name": "The Skin Space",
//         "phone": "6311 8869",
//         "address": "12/F, C Wisdom Centre, 35-37 Hollywood Rd, Central"
//       },
//       {
//         "id": 40,
//         "district": "香港島",
//         "name": "Lumi Skincare",
//         "phone": "6288 1555",
//         "address": "銅鑼灣謝斐道482號兆安廣場19樓"
//       },
//       {
//         "id": 42,
//         "district": "香港島",
//         "name": "Alluna Medical",
//         "phone": "9858 6625",
//         "address": "香港銅鑼灣百德新街2-20號恒隆中心20樓2015A室"
//       },
//       {
//         "id": 45,
//         "district": "香港島",
//         "name": "Perfect Medical (CWB)",
//         "phone": "2782 0550",
//         "address": "銅鑼灣禮頓山道禮頓山1座時代廣場28樓2801室"
//       },
//       {
//         "id": 47,
//         "district": "香港島",
//         "name": "Miioskin (Central)",
//         "phone": "2658 5113",
//         "address": "中環蘭桂坊1號2-3樓、13樓全層"
//       },
//       {
//         "id": 57,
//         "district": "香港島",
//         "name": "EvrBeauty Medical Aesthetics (CWB)",
//         "phone": "6657 2212",
//         "address": "香港銅鑼灣百德新街2-20號恆隆中心18樓01-02室"
//       },
//       {
//         "id": 59,
//         "district": "香港島",
//         "name": "JuveMedico Centre Co. Limited",
//         "phone": "2882 6889",
//         "address": "香港皇后大道中152-156號皇后大道中心5樓"
//       },
//       {
//         "id": 60,
//         "district": "香港島",
//         "name": "Beauskin Med Group (CWB)",
//         "phone": "3188 5638",
//         "address": "銅鑼灣告士打道280號世界貿易中心3001-3003, 30/F"
//       },
//       {
//         "id": 68,
//         "district": "香港島",
//         "name": "Skin Origin",
//         "phone": "9222 0053",
//         "address": "銅鑼灣登龍街1號金朝陽中心二期MIDTOWN 19樓B室"
//       },
//       {
//         "id": 70,
//         "district": "香港島",
//         "name": "Surrface Beauty & Medical",
//         "phone": "2381 3388",
//         "address": "銅鑼灣霎東街11號The Sharp8樓全層"
//       },
//       {
//         "id": 72,
//         "district": "香港島",
//         "name": "Beverly Skin Refining Center",
//         "phone": "2117 0046",
//         "address": "銅鑼灣軒尼詩道489號銅鑼灣廣場一期20樓03A室"
//       },
//       {
//         "id": 74,
//         "district": "香港島",
//         "name": "MIIO MEDICAL",
//         "phone": "9088 6476",
//         "address": "銅鑼灣恆隆中心2103-06"
//       }
//     ],
//   },
//   kowloon: {
//     total: 50,
//     list: [
//       {
//         "name": "Phiderma Aesthetics Centre",
//         "phone": "2368 9887",
//         "address": "香港尖沙咀加連威老道2-6號愛賓商業大廈8樓"
//       },
//       {
//         "name": "Medicskin",
//         "phone": "2526 9333",
//         "address": "香港中環新世界大廈 2 期 21樓"
//       },
//       {
//         "name": "Sphere Medical Aesthetic Centre",
//         "phone": "2911 0022",
//         "address": "香港中環砵典乍街十號四樓全層"
//       },
//       {
//         "name": "Qualia Medical Spa",
//         "phone": "3596 7378",
//         "address": "尖沙咀廣東道82號84 18樓"
//       },
//       {
//         "name": "Med-thical Healthcare Laser and Skin Centre",
//         "phone": "6774 8178",
//         "address": "尖沙咀彌敦道132號美麗華廣場A座9樓912室"
//       },
//       {
//         "name": "Cutis",
//         "phone": "2332 8751",
//         "address": "九龍旺角彌敦道664號惠豐中心18樓全層"
//       },
//       {
//         "name": "RENAISSAGE BEAUTY INSTITUTE",
//         "phone": "5577 6223",
//         "address": "中環皇后大道中陸佑行304"
//       },
//       {
//         "name": "Cosderma",
//         "phone": "3188 0478",
//         "address": "香港銅鑼灣禮頓道77號禮頓中心8樓820室"
//       },
//       {
//         "name": "Vitae (TST)",
//         "phone": "3929 1838",
//         "address": "尖沙咀中間道18號半島辦公大樓16樓"
//       },
//       {
//         "name": "Vitae By CosMax (Central)",
//         "phone": "3929 1888",
//         "address": "皇后大道中16-18號新世界大廈2座11樓"
//       },
//       {
//         "name": "Vitae (CWB)",
//         "phone": "3929 1838",
//         "address": "香港銅鑼灣羅素街38號金朝陽中心22樓"
//       },
//       {
//         "name": "LUXMED (MACAU)",
//         "phone": "852 3589 6758",
//         "address": "澳門南灣湖景大馬路財神商業中心19樓"
//       },
//       {
//         "name": "Hong Kong Hearts",
//         "phone": "3589 6758",
//         "address": "尖沙咀廣東道海港城海洋中心15樓1510-1512A室"
//       },
//       {
//         "name": "Heal (Aesthetic)",
//         "phone": "",
//         "address": "中環華懋中心2期30樓"
//       },
//       {
//         "name": "Life Medical Centre",
//         "phone": "2835 8199",
//         "address": "澳門南灣大馬路599號羅德禮商業大廈8樓B室"
//       },
//       {
//         "name": "Be Me Aesthetic",
//         "phone": "2508 7962",
//         "address": "銅鑼灣恩平道42號亨利中心21樓"
//       },
//       {
//         "name": "Life Young -Central",
//         "phone": "9699 1312",
//         "address": "中環雪廠街16號西洋會所大廈8樓"
//       },
//       {
//         "name": "Anova Medical Esthetic",
//         "phone": "3421 0491",
//         "address": "旺角彌敦道677號恆生旺角大廈19樓"
//       },
//       {
//         "name": "Go Young Skinlab Medical Beauty",
//         "phone": "9456 5841",
//         "address": "旺角彌敦道610號荷李活商業中心13樓1302室"
//       },
//       {
//         "name": "Life young (TST)",
//         "phone": "9699 1312",
//         "address": "尖沙咀海港城世界商業中心9樓911-914室"
//       },
//       {
//         "name": "Modelage",
//         "phone": "3188 3158",
//         "address": "銅鑼灣世貿中心36樓01室"
//       },
//       {
//         "name": "All Beauty Medical Centre",
//         "phone": "2788 4998",
//         "address": "油麻地彌敦道238號1101室"
//       },
//       {
//         "name": "Ever Beauty Aesthetic & Laser",
//         "phone": "2537 3928",
//         "address": "香港中環德輔道中71號永安集團大廈5字樓510室"
//       },
//       {
//         "name": "Nume (CWB)",
//         "phone": "2295 0988",
//         "address": "銅鑼灣告士打道255 - 257號信和廣場25樓2505室"
//       },
//       {
//         "name": "Nume (TST)",
//         "phone": "2722 5922",
//         "address": "尖沙咀彌敦道132號一期 26樓2601室美麗華廣場"
//       },
//       {
//         "name": "Pean Beauty",
//         "phone": "2887 3299",
//         "address": "香港尖沙咀廣東道33號中港城第一座8樓801C室"
//       },
//       {
//         "name": "Beaunique Medical Aesthetics",
//         "phone": "6298 3288",
//         "address": "旺角登打士街56號家樂坊1111室"
//       },
//       {
//         "name": "Beaunique Medical Aesthetics",
//         "phone": "6298 3288",
//         "address": "銅鑼灣告士打道280號02-03室世界貿易中心20樓"
//       },
//       {
//         "name": "Natural House Beauty Center",
//         "phone": "2788 3133",
//         "address": "銅鑼灣京都商業中心15樓全層"
//       },
//       {
//         "name": "Medicglow",
//         "phone": "2522 2205",
//         "address": "銅鑼灣霎東街11-13號The Sharp17樓A室"
//       },
//       {
//         "name": "Skin & Co.",
//         "phone": "2178 3033",
//         "address": "香港銅鑼灣告士打道280號世貿中心"
//       },
//       {
//         "name": "PerFace (CWB)",
//         "phone": "6618 5618",
//         "address": "世貿中心35樓3501-03"
//       },
//       {
//         "name": "Queen Mary Hospital",
//         "phone": "2255 3838",
//         "address": "薄扶林薄扶林道瑪麗醫院正院"
//       },
//       {
//         "name": "Face Skin",
//         "phone": "3188 4717",
//         "address": "香港九龍尖沙咀天文台道8號903室"
//       },
//       {
//         "name": "Medskin",
//         "phone": "2808 0889",
//         "address": "尖沙咀彌敦道132號美麗華大廈一期6樓617室"
//       },
//       {
//         "name": "Lambre Health",
//         "phone": "9711 4220",
//         "address": "尖沙咀彌敦道132號美麗華廣場一期19樓1917室"
//       },
//       {
//         "name": "The Skin Space",
//         "phone": "6311 8869",
//         "address": "12/F, C Wisdom Centre, 35-37 Hollywood Rd, Central"
//       },
//       {
//         "name": "Angel Concept",
//         "phone": "6288 1555",
//         "address": "尖沙咀加拿分道18號恒生尖沙咀大廈1301室"
//       },
//       {
//         "name": "Lumi Skincare",
//         "phone": "6288 1555",
//         "address": "銅鑼灣謝斐道482號兆安廣場19樓"
//       },
//       {
//         "name": "Mirror Skin Management Centre",
//         "phone": "6137 9228",
//         "address": "尖沙咀漆咸道南57-59號金馬倫中心10/F"
//       },
//       {
//         "name": "Alluna Medical",
//         "phone": "9858 6625",
//         "address": "香港銅鑼灣百德新街2-20號恒隆中心20樓2015A室"
//       },
//       {
//         "name": "Vita Medical Center",
//         "phone": "",
//         "address": "澳門南灣大馬路815號才能商業中心"
//       },
//       {
//         "name": "Perfect Medical (MK)",
//         "phone": "2782 0550",
//         "address": "旺角亞皆老街8號朗豪坊辦公大樓43樓 4301室"
//       },
//       {
//         "name": "Perfect Medical (CWB)",
//         "phone": "2782 0550",
//         "address": "銅鑼灣禮頓山道禮頓山1座時代廣場28樓2801室"
//       },
//       {
//         "name": "Miioskin (TST)",
//         "phone": "2658 5313",
//         "address": "尖沙咀海港城世界商業中心1008室"
//       },
//       {
//         "name": "Miioskin (Central)",
//         "phone": "2658 5113",
//         "address": "中環蘭桂坊1號2-3樓、13樓全層"
//       },
//       {
//         "name": "Love Loves Beauty",
//         "phone": "6560 1218",
//         "address": "旺角西洋菜南街168號168大廈10樓1003室"
//       },
//       {
//         "name": "Beauskin Medical Group Ltd",
//         "phone": "3188 5638",
//         "address": "銅鑼灣告士打道280號世界貿易中心3001-3003, 30/F"
//       },
//       {
//         "name": "Beauty Ease",
//         "phone": "2411 4881",
//         "address": "荃灣青山公路181-183號 2/F"
//       },
//       {
//         "name": "Gardenia Medical Limited",
//         "phone": "2388 9996",
//         "address": "香港九龍尖沙咀彌敦道132號美麗華廣場A座7樓7O7室"
//       },
//       {
//         "name": "Aesme",
//         "phone": "9806 2023",
//         "address": "九龍尖沙咀漢口道28號亞太中心308-9"
//       },
//       {
//         "name": "Hong Kong Skin Center",
//         "phone": "2376 2533",
//         "address": "尖沙咀彌敦道132號美麗華廣場A座17樓1717-18室"
//       },
//       {
//         "name": "JM Plus Clinic",
//         "phone": "3565 4920",
//         "address": "尖沙咀金馬倫道18-20號(The Mangan) 萬勤商業大廈8樓B室"
//       },
//       {
//         "name": "EvrBeauty Medical Aesthetics (TST)",
//         "phone": "6365 7694",
//         "address": "美麗華廣場一期6樓618室彌敦道132號"
//       },
//       {
//         "name": "King Qualia Medical (TST)",
//         "phone": "3596 7378",
//         "address": "尖沙咀廣東道82-84流尚坊18樓"
//       },
//       {
//         "name": "JuveMedico Centre Co. Limited",
//         "phone": "2882 6889",
//         "address": "香港皇后大道中152-156號皇后大道中心5樓"
//       },
//       {
//         "name": "Dear Beauty",
//         "phone": "5916 6616",
//         "address": "香港尖沙咀分店廣東道10-12號松鳳商業大廈15/F全層"
//       },
//       {
//         "name": "Sammy Beauty",
//         "phone": "2724 8777",
//         "address": "香港九龍尖沙咀金馬倫道5號太興廣場12樓全層"
//       },
//       {
//         "name": "RenMed Medical Beauty Lounge",
//         "phone": "5703 3267",
//         "address": "九龍尖沙咀廣東道12號松鳳商業大廈12 樓全層"
//       },
//       {
//         "name": "LHY Beaute Lab Co Limited",
//         "phone": "6507 5791",
//         "address": "尖沙咀樂道27號樂輝商業大廈8樓全層"
//       },
//       {
//         "name": "Glowin' Beaute",
//         "phone": "5548 3509",
//         "address": "尖沙咀彌敦道132號美麗華廣場A座616室"
//       },
//       {
//         "name": "Vinca Beauty",
//         "phone": "5166 6252",
//         "address": "尖沙咀加拿分道20號加拿芬廣場1601室"
//       },
//       {
//         "name": "EvrBeauty Medical Aesthetics (MK)",
//         "phone": "5985 8428",
//         "address": "旺角亞皆老街 8 號朗豪坊辦公大樓40 樓 4001 室"
//       },
//       {
//         "name": "GeelStore",
//         "phone": "2633 8908",
//         "address": "旺角登打士街 56 號家樂坊 1314 - 1317 室"
//       }
//     ],
//   },
//   newTerritories: {
//     total: 4,
//     list: [
//       {
//         "id": 1,
//         "district": "新界",
//         "name": "Beauty Ease",
//         "phone": "2411 4881",
//         "address": "荃灣青山公路181-183號 2/F"
//       },
//       {
//         "id": 2,
//         "district": "新界",
//         "name": "Bonnie Beauty",
//         "phone": "9248 3663",
//         "address": "元朗俊賢坊28號安基大廈地下4號舖"
//       }
//     ],
//   },
//   macao: {
//     total: 4,
//     list: [{
//       "id": 1,
//       "district": "澳門",
//       "name": "Stellar M22 - Life Medical Centre",
//       "phone": "2835 8199",
//       "address": "澳門南灣大馬路599號羅德禮商業大廈8樓B室"
//     },
//     {
//       "id": 2,
//       "district": "澳門",
//       "name": "Stellar M22 - OMyGirl",
//       "phone": "6299 8331",
//       "address": "澳門渡船街14F號"
//     },
//     {
//       "id": 3,
//       "district": "澳門",
//       "name": "Stellar M22 - OMyGirl",
//       "phone": "6538 8338",
//       "address": "澳門氹仔廣東大馬路515號, 星皓廣場, 二層 218號舖"
//     },
//     {
//       "id": 4,
//       "district": "澳門",
//       "name": "Stellar M22 - W Royal Beauty ",
//       "phone": "6300 1239",
//       "address": "澳門南灣巷雅苑閣10號ABC地下"
//     },
//     ]
//   }
// };

let totalServiceCenter = 0;
let allServiceCenterList = [];
Object.values(serviceCenterList).forEach((serviceCenter) => {
  totalServiceCenter += serviceCenter.total;
  allServiceCenterList = allServiceCenterList.concat(serviceCenter.list);
});

export default function ServiceCenter() {
  const [select, setSelect] = useState("all");
  const [page, setPage] = useState(0);
  const perPage = 3;

  const [displayServiceCenter, setDisplayServiceCenter] = useState({
    total: 0,
    list: [],
  });

  function handlePage(direction) {
    if (direction === -1 && page > 0) {
      setPage(page - 1);
    }
    if (
      direction === 1 &&
      page < Math.ceil(displayServiceCenter.total / 3) - 1
    ) {
      setPage(page + 1);
    }
  }

  useEffect(() => {
    setPage(0);
  }, [select]);

  useEffect(() => {
    if (select === "all") {
      let arr = [];
      _.forEach(_.keys(serviceCenterList), function (key, val) {
        _.filter(serviceCenterList[key].list, function (o) {
          if (window.location.pathname == "/m22" && o.product === "m22") {
            arr.push(o);
            return;
          } else if (
            window.location.pathname == "/stellar" &&
            o.product === "splendorX"
          ) {
            arr.push(o);
            return;
          }
        });
      });
      if (
        window.location.pathname != "/stellar" &&
        window.location.pathname != "/m22"
      ) {
        setDisplayServiceCenter({
          total: totalServiceCenter,
          list: allServiceCenterList.slice(
            page * perPage,
            page * perPage + perPage
          ),
        });
      } else {
        setDisplayServiceCenter({
          total: arr.length,
          list: arr.slice(page * perPage, page * perPage + perPage),
        });
      }
    } else if (Object.keys(serviceCenterList).includes(select)) {
      if (window.location.pathname === "/m22") {
        let arr = [];
        _.filter(serviceCenterList[select].list, function (o) {
          if (o.product === "m22") {
            arr.push(o);
            // serviceCenterList[select].list = arr;
            console.log("filter", arr);
            // serviceCenterList[select].total = arr.length;
          }
        });

        let slice = arr.slice(page * perPage, page * perPage + perPage);

        setDisplayServiceCenter({
          total: arr.length,
          list: slice,
        });
        // setDisplayServiceCenter({
        //   total: arr.length,
        //   list: arr.slice(
        //       page * perPage,
        //       page * perPage + perPage
        //   ),
        // });
        // setDisplayServiceCenter({
        //   total: serviceCenterList[select].total,
        //   list: serviceCenterList[select].list.slice(
        //     page * perPage,
        //     page * perPage + perPage
        //   ),
        // });
      } else if (window.location.pathname === "/stellar") {
        let arr = [];
        _.filter(serviceCenterList[select].list, function (o) {
          if (o.product === "splendorX") {
            arr.push(o);
            // serviceCenterList[select].list = arr;
            console.log("filter", arr);
            // serviceCenterList[select].total = arr.length;
          }
        });
        setDisplayServiceCenter({
          total: arr.length,
          list: arr.slice(page * perPage, page * perPage + perPage),
        });
        // setDisplayServiceCenter({
        //   total: serviceCenterList[select].total,
        //   list: serviceCenterList[select].list.slice(
        //     page * perPage,
        //     page * perPage + perPage
        //   ),
        // });
      } else {
        setDisplayServiceCenter({
          total: serviceCenterList[select].total,
          list: serviceCenterList[select].list.slice(
            page * perPage,
            page * perPage + perPage
          ),
        });
      }
    }
  }, [select, page]);

  return (
    <div className="p-6 md:px-10 lg:px-[10%] mb-5">
      <h2 className="text-2xl font-semibold mb-5">尋找服務中心</h2>
      <div className="overflow-x-scroll no-scrollbar mb-5">
        <div className="flex gap-2 [&>div]:rounded-xl [&>div]:py-1 [&>div]:w-20 [&>div]:font-semibold [&>div]:text-center [&>div]:tracking-widest [&>div]:cursor-pointer">
          <div
            style={{
              backgroundColor: select === "all" ? "#333333" : "#ffffff",
              color: select === "all" ? "#ffffff" : "#333333",
              border: "solid 1px #333333",
            }}
            onClick={() => setSelect("all")}
          >
            ALL
          </div>
          <div
            style={{
              backgroundColor:
                select === "hongKongIsland" ? "#333333" : "#ffffff",
              color: select === "hongKongIsland" ? "#ffffff" : "#333333",
              border: "solid 1px #333333",
            }}
            onClick={() => setSelect("hongKongIsland")}
          >
            香港島
          </div>
          <div
            style={{
              backgroundColor: select === "kowloon" ? "#333333" : "#ffffff",
              color: select === "kowloon" ? "#ffffff" : "#333333",
              border: "solid 1px #333333",
            }}
            onClick={() => setSelect("kowloon")}
          >
            九龍
          </div>
          <div
            style={{
              backgroundColor:
                select === "newTerritories" ? "#333333" : "#ffffff",
              color: select === "newTerritories" ? "#ffffff" : "#333333",
              border: "solid 1px #333333",
            }}
            onClick={() => setSelect("newTerritories")}
          >
            新界
          </div>
          {/* <div
            style={{
              backgroundColor:
                select === "macao" ? "#333333" : "#ffffff",
              color: select === "macao" ? "#ffffff" : "#333333",
              border: "solid 1px #333333",
            }}
            onClick={() => setSelect("macao")}
          >
            澳門
          </div> */}
        </div>
      </div>
      <div>
        <div className="mb-5">共 {displayServiceCenter.total} 個結果</div>
        <div className="flex flex-wrap justify-between">
          {displayServiceCenter.list.map((serviceCenter, index) => {
            return (
              <div
                className="grid grid-cols-3 gap-4 mb-5 w-full md:w-[45%]"
                key={`${select}-${index}`}
              >
                <div className="col-span-1">
                  <img
                    className="w-full max-h-32 object-contain"
                    src={
                      serviceCenter.logo === ""
                        ? "/images/sample-logo.png"
                        : `/images/center/${serviceCenter.logo}`
                    }
                    alt=""
                  />

                  {/* <img className="w-full" src="/images/sample-logo.png" alt="" /> */}
                </div>
                <div className="col-span-2 flex flex-col gap-2">
                  <div className="text-lg font-semibold">
                    {serviceCenter.name}
                  </div>
                  <div>{serviceCenter.address}</div>
                  <div className="flex gap-2 items-center text-lg">
                    <Phone />
                    {serviceCenter.phone}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row-reverse gap-2">
        <div
          className="cursor-pointer py-[10px] px-3 rounded-full border-2 border-solid border-black"
          onClick={() => handlePage(1)}
        >
          <RightArrow />
        </div>
        <div
          className="cursor-pointer py-[10px] px-3 rounded-full border-2 border-solid border-black"
          onClick={() => handlePage(-1)}
        >
          <LeftArrow />
        </div>
      </div>
    </div>
  );
}
