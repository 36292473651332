"use client";

import { useState, useRef, useEffect } from "react";
import "../global.css";
import { Button } from "antd";
import { UpDownArrow } from "../svg/arrow";
import { Dropdown, Space } from "antd";
import DropdownCustom from "../components/dropdown";
import ServiceCenter from "../components/serviceCenter";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TermAndCondition from "../components/termAndCondition";
import DocumentMeta from "react-document-meta";
import MenuCustom from "../components/menu";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-9XTPFNX5EE"; // your Measurement ID
const meta = {
  title: "Splendor X永久脫毛技術 - Lumenis香港",
  description:
    "Splendor X採用專利雙波長BLEND X™技術，同時輸出及調校755nm & 1064nm雙波長比例，比一般單一激光或彩光脫毛效果更快、更準；配合首創方形機頭設計及雙重冰感降溫系統，獲美國FDA及歐盟CE認證，實現真正舒適永久脫毛**。",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "react,meta,document,html,tags, Splendor X",
    },
  },
};

const items = [
  {
    label: "SPLENDOR X 技術",
    key: "Item-1",
  },
  {
    label: "SPLENDOR X 脫毛技術比較",
    key: "Item-2",
  },
  {
    label: "FAQ",
    key: "Item-3",
  },
  // {
  //   label: "實證分享",
  //   key: "Item-4",
  // },
  {
    label: "尋找服務中心",
    key: "Item-5",
  },
];


// let card = [
//   {
//     icon: "/images/p2_img4.png",
//     title: "雙重冰感降溫系統",
//     content:
//       "SPLENDOR X特別設計DCS雙重冰感系統，結合Cryo-Touch冰極觸感及Cryo-Air冰極氣旋噴射技術，將冷風準確送到光束觸及的位置，能全面減低皮膚所承受的熱能，將灼傷及痛感減至最低，如比堅尼敏感部位也可舒適脫毛。",
//   },
//   {
//     icon: "",
//     title: "方形激光脫毛機頭",
//     content:
//       "SPLENDOR X採用創新方形設計，確保不會像其他圓形探頭容易重疊覆蓋位置，防遺漏之餘亦大大減低灼傷皮膚。",
//   },
//   {
//     icon: "",
//     title: "極速激光脫毛",
//     content:
//       "SPLENDOR X採用創新特大27X27mm方形設計機頭，覆蓋範圍更大；每秒輸出3發能量，全面提升每次療程效率。",
//   },
// ];

function StellarPage() {
  const [item, setItem] = useState("Item-1");
  const [cardImage, setCardImage] = useState("/images/p2-card/p2_img1.png");
  const [cardImageNum, setCardImageNum] = useState(1);

  useEffect(() => {
    if (cardImageNum === 0) {
      setCardImageNum(1);
    }
    if (cardImageNum === 4) {
      setCardImageNum(3);
    }
    setCardImage(`/images/p2-card/p2_img${cardImageNum}`);
  }, [cardImageNum]);
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/stella", title: "stella" });
  }, [])


  const onClick = ({ key }) => {
    console.log("keyyyy", key);
    if (key === "Item-1") {
      document.getElementById("item1").scrollIntoView();
    }
    if (key === "Item-2") {
      document.getElementById("item2").scrollIntoView();
    }
    if (key === "Item-3") {
      document.getElementById("item3").scrollIntoView();
    }
    if (key === "Item-5") {
      document.getElementById("item5").scrollIntoView();
    }
    // ref.current?.scrollIntoView({behavior: 'smooth'});
    //setItem(key);
  };
  const ref = useRef(null);

  return (
    <DocumentMeta {...meta}>
      <div
        style={{ color: "#333333", fontFamily: "Noto Sans HK" }}
        className="[&>*]:w-full w-full"
      >
        <Header />
        <div className="w-full">
          <picture className="w-full object-cover">
            <source media="(min-width:1024px)" srcSet="/images/p2_logo_3.png" />
            <source media="(min-width:768px)" srcSet="/images/p2_logo_2.png" />
            <img src="/images/p2_logo.png" alt="" />
          </picture>
        </div>
        <Dropdown
          trigger={["click"]}
          menu={{ items, onClick }}
          className="px-4 flex justify-between h-16 !text-xl font-semibold md:!hidden"
        >
          <Space>
            SPLENDOR X 技術
            <UpDownArrow />
          </Space>
        </Dropdown>
        <MenuCustom
          className={"hidden md:flex font-semibold text-xl"}
          currentItemKey={item}
          items={items}
          onClick={onClick}
        />
        <div
          id="item1"
          ref={ref}
          className="[&>*]:text-center [&>*]:tracking-wide [&>*]:mt-10 p-4 md:px-8 lg:px-[10%]"
        >
          <h2 className="text-2xl font-semibold" style={{ color: "#191357" }}>
            全球首創極快無痛
            <br />
            永久脫毛儀器
          </h2>
          <p>
            有別於一般激光或彩光脫毛，SPLENDOR
            X採用全球首創專利雙波長真同步發射BLEND
            X™技術，提升療程效果和大大縮減療程時間。
          </p>
          <p>
            配合首創方形機頭設計，避免接受療程的部位過份重疊受傷，具備智能雙重冷凍技術而無須使用化學冷凍劑！
          </p>
          <p>
            經研究證實比傳統755單一激光更深入有效*
            ，痛感少63.8%*，療程低風險高成效，無論膚色深淺，甚至敏感嬌嫩部位，都能享受舒適脫毛。
          </p>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8 mt-10 md:mt-5">
          <img
            src="/images/p2_img0-2.png"
            alt=""
            className="w-[80%] md:w-[60%] h-full object-contain md:max-w-[330px]"
          />
          <picture className="w-[80%] md:w-[30%] h-full min-h-full object-contain md:max-w-[330px]">
            <source media="(min-width:768px)" srcSet="/images/p2_img1-2.png" />
            <img
              src="/images/p2_img1-1.png"
              alt=""
              className="w-full h-full object-contain md:max-w-[330px]"
            />
          </picture>
        </div>

        <div className="flex flex-col items-center mt-32 pb-10 bg-gradient-to-b from-[#256CF5] via-[#7FA0F5] to-[#5BA7FF]">
          <picture className="h-auto w-full">
            <source
              media="(min-width:768px)"
              srcSet="/images/p2_img4-4.png"
              className="filter brightness-200"
            />
            <img
              src="/images/p2_img4.png"
              className="h-full w-full object-contain"
              alt=""
            />
          </picture>
          <p className="hidden md:block w-2/3 md:my-6 lg:my-8 text-[#191357] text-2xl text-center lg:text-4xl font-medium">
            一次直撃不同深度毛囊根源 以準確技術，快捷完成療程
          </p>
          <picture className="h-auto w-2/3">
            <source
              media="(min-width:768px)"
              srcSet={`${cardImage}-2.png`}
              className="filter brightness-200"
            />
            <img
              src={`${cardImage}-1.png`}
              className="h-full w-full object-contain"
              alt=""
            />
          </picture>
          {/* <div   className="mt-5 " style={{ paddingRight: 30 }}>
        <Button onClick={() => setCaseStudyImageNum(caseStudyImageNum + 1)} style={{ float: 'right' }} danger shape="circle" icon={">"} />
        <Button onClick={() => setCaseStudyImageNum(caseStudyImageNum - 1)} style={{ float: 'right', marginRight: 5 }} danger shape="circle" icon={"<"} />
      </div> */}
        </div>
        <div className="mt-5 pr-8">
          <Button
            onClick={() => setCardImageNum((cardImageNum) => cardImageNum + 1)}
            style={{ float: "right", borderColor: "#629FFC" }}
            type="primary"
            ghost
            shape="circle"
            icon={">"}
          />
          <Button
            onClick={() => setCardImageNum(cardImageNum - 1)}
            style={{ float: "right", marginRight: 5, borderColor: "#629FFC" }}
            type="primary"
            ghost
            shape="circle"
            icon={"<"}
          />
        </div>

        <div className="p-6 md:px-10 lg:px-[10%] text-center mt-8">
          <div className="text-[#777777] text-sm text-left mb-2">
            2 Promises
          </div>
          <img
            src="/images/p2_img6.png"
            alt=""
            className="w-[6rem] md:w-[8rem] z-10"
          />
          <div className="my-4 flex flex-row justify-center">
            <img src="/images/p2_img7.png" alt="" className="w-2/3" />
          </div>
        </div>

        <div id="item2"></div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28">
          <div style={{ color: "#777777" }} className="text-sm mb-1">
            Technology Comparison
          </div>
          <div style={{ color: "#191357" }} className="text-2xl font-bold mb-5">
            技術比較
          </div>
          <div className="overflow-x-scroll customize-scrollbar">
            <table className="text-left">
              <thead className="[&>tr>th]:p-4 [&>tr>th]:font-semibold">
                <tr>
                  <th style={{ color: "#191357" }}>Splendor X雙激光</th>
                  <th>其他激光脫毛</th>
                  <th>其他一般彩光脫毛</th>
                </tr>
              </thead>
              <tbody className="[&>tr>th]:p-4 [&>tr>td]:p-4 [&>tr>th]:min-w-52 [&>tr>td]:min-w-52">
                <tr className="bg-blue-400 text-white">
                  <td colSpan={3} className="!py-1 !px-4">
                    療程原理
                  </td>
                </tr>
                <tr>
                  <th style={{ color: "#191357" }} className="font-semibold">
                    採用雙波長光學原理，直接破壞毛囊組織，研究證實比傳統755單一激光更深入有效*
                    ，痛感少63.8%*
                  </th>
                  <td>單一波長光學原理，直接破壞毛囊組織</td>
                  <td>寬頻彩光源，利用選擇性光熱效應，能量或較為保守</td>
                </tr>
                <tr className="bg-blue-400 text-white">
                  <td colSpan={3} className="!py-1 !px-4">
                    能量輸出
                  </td>
                </tr>
                <tr>
                  <th style={{ color: "#191357" }} className="font-semibold">
                    真同步輸出雙波長，同時打擊毛囊底部，和底部下供應養分的位置
                  </th>
                  <td>
                    單一或不同步的能量發射，未能同時打擊毛囊底部，和底部下供應養分的位置
                  </td>
                  <td>能量分散，毛囊位置不能集中吸收熱能</td>
                </tr>
                <tr className="bg-blue-400 text-white">
                  <td colSpan={3} className="!py-1 !px-4">
                    療程時間
                  </td>
                </tr>
                <tr>
                  <th style={{ color: "#191357" }} className="font-semibold">
                    極快，市場最大探頭27 x27 mm
                  </th>
                  <td>治療時間較長</td>
                  <td>需要搽冷凍凝膠，治療時間更長</td>
                </tr>
                <tr className="bg-blue-400 text-white">
                  <td colSpan={3} className="!py-1 !px-4">
                    痛感
                  </td>
                </tr>
                <tr>
                  <th style={{ color: "#191357" }} className="font-semibold">
                    創新方形特大機頭設計 更深、更廣、更精準的永效脫毛
                  </th>
                  <td>
                    傳統細小圓形機頭，會有遺漏和重疊位置，拖慢療程進展和有灼傷風險
                  </td>
                  <td>較難達致永久效果，需定期再做</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="item3"></div>
        <div className="p-6 md:px-10 lg:px-[10%] mt-10 md:mt-16 lg:mt-28 [&>hr]:my-4">
          <h3 className="font-bold text-2xl mb-5" style={{ color: "#191357" }}>
            FAQ
          </h3>
          <DropdownCustom title={"1. Splendor X 採用了甚麼技術？"}>
            Splendor X採用榮獲意大利專利獨家首創BLEND
            X™激光技術，療程時可同步輸出755nm &
            1064nm雙波長，一次過直撃不同深度的毛囊根源，準確快捷完成治療。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"2. Splendor X與一般激光或彩光脫毛有甚麼分別？"}
          >
            Splendor
            X能做到真同步輸出雙波長，能達致比單一激光或彩光更深、更廣、更精準的永久脫毛效果；具備創新方形特大機頭，減少遺漏和重疊情況，研究證實比傳統755單一激光更深入有效*
            ，痛感少63.8%*，療程更加安全。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"3. Splendor X適合亞洲人毛髮嗎？"}>
            Splendor
            X可同步輸出並調校755nm及1064nm兩種波長比例，滿足不同皮膚深淺、毛髮顏色及粗幼度的需要，無論是亞洲或其他地區人士均適合使用
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"4. Splendor X激光會引致皮膚敏感嗎？"}>
            不會。Splendor
            X機頭無須使用化學冷凍劑，具備DCS雙重冰感系統，結合Cryo-Touch冰極觸感及Cryo-Air冰極氣旋噴射技術，將冷風準確送到光束觸及的位置，將灼傷或敏感風險減至最低。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"5. 做完會立即見效嗎？整個療程會建議做多少次？"}
          >
            Splendor
            X激光每次完成治療後均能立即見效，整個療程建議做大約6次，實際效果因應個人之毛髮顏色、粗幼、數量及生長週期而有所不同。。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"6. 建議相隔多久做一次療程？"}>
            建議根據不同毛髮生長週期設定療程。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={"7. 皮膚敏感人士、懷孕或免疫系統疾病人士適合使用嗎？"}
          >
            Splendor
            X機頭具備的DCS雙重冰感系統能減少皮膚敏感風險，然而懷孕及免疫系統疾病人士則不建議使用。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"8. 療程前後有甚麼注意事項？"}>
            療程前後要避免進行去角質或美白等刺激的保養，亦應做好防曬程序。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"9. 療程後可以曬太陽嗎？"}>
            療程後不建議即時曬太陽，以免令皮膚產生敏感反應。
          </DropdownCustom>
          <hr />
          <DropdownCustom
            title={
              "10. 如接受了其他療程（如陶瓷肌），須隔多久才可接受Splendor X療程？"
            }
          >
            如接受了其他療程，建議待皮膚完全修復、或至少相隔一個月才接受Splendor
            X療程。
          </DropdownCustom>
          <hr />
          <DropdownCustom title={"11. 除了用在面部，其他身體部位也能使用嗎？"}>
            Splendor X適合用於所有身體部位，即使敏感部位也可享有無痛脫毛的體驗。
          </DropdownCustom>
          <hr />

          <div
            className="my-8"
            style={{ fontSize: "8px", color: "#999999" }}
          >
            **U.S. FDA CLEARED K161632 FDA認證適合所有皮膚顏色 (Fitzpatrick
            I-VI)
            ，療程後12個月內，毛髮穩定減少再生，但不一定能夠完全清除治療位置的所有毛髮。實際效果因應個人之毛髮顏色、粗幼、數量及生長週期而有所不同。
            ***Courtesy of Leonardo Marini, SDC The Skin Doctors’ Center Trieste
            (I) Laser-Assisted Permanent Hair Reduction with an innovative
            simultaneous combination of 755nm and 1064nm and a square spot
            shape: A comparative evaluation
          </div>
        </div>

        <div className="mt-10 md:mt-16 lg:mt-28" id="item5"></div>
        <ServiceCenter />

        {/* <div class="p-4 grid grid-rows-3 grid-flow-col gap-4" style={{ fontSize: 14, color: "#000000", backgroundColor: '#FAFAFA' }}>
        <div class="row-span-3" style={{ padding: '10px 0px'}}>免責聲明</div>
        <div class="row-span-3" style={{ color: "#000000", textAlign: 'right', padding: '10px 0px' }}>></div>
      </div> */}
        <TermAndCondition />
        <Footer />
      </div>
    </DocumentMeta>
  );
}

export default StellarPage;
