"use client";

export default function termAndCondition() {
  return (
    <a href="disclaimer">
      <div
        className="p-4 grid grid-rows-3 grid-flow-col gap-4"
        style={{ fontSize: 14, color: "#000000", backgroundColor: "#FAFAFA" }}
      >
        <div className="row-span-3" style={{ padding: "10px 0px" }}>
          免責聲明
        </div>
        <div
          className="row-span-3"
          style={{ color: "#000000", textAlign: "right", padding: "10px 0px" }}
        />
      </div>
    </a>
  );
}
