"use client";


import { useEffect, useState } from "react";
import { Breadcrumb, Layout, Menu, Avatar, Carousel, Card, Button, theme, Row, Col, Drawer, Image, Anchor } from 'antd';
import Header from "../components/Header";
import Footer from "../components/Footer";


export default function privatePolicy() {
    return (
        <div
            style={{ color: "#333333", fontFamily: "Noto Sans HK" }}
            className="[&>*]:w-full w-full"
        >
            <Header />
            <div>
                <h1>私隱政策</h1>
                瀏覽Lumenis BE(HK) Limited 以下統稱為「我們」、「我們的」、「本公司」)的網站和填寫網站登記表格前，請先詳細閱讀以下與使用您個人資料相關的條款和細則。並請同意及承諾不允許他人使用您的身份及不違反法律法規。
                <br />
                私隠政策只適用於由網站所取得的個人資料，包括但不限於登記內容、資料查詢，以及用於識別個人身份的資料，例如姓名、電話、電郵地址、IP資料等。此政策將不時進行修訂。如在我們修訂本私隱政策後繼續使用網站，即代表您接受此等修訂，請定期查閱以了解最新版本資訊。
                <br />
                在使用個人資料方面，我們將遵守私隱政策中的相關規定，詳情如下。 <br />
                <ol>
                    <li>1. 我們會將網站收集的資訊用作統計數據，例如衡量網站表現或額外使用分析工具作統計數據)。全部數據不包含可識別個人身份的數據，也不會用作收集個人資料。</li>
                    <li>2. 我們會使用 Cookie 收集個人資料，您亦可以選擇拒絕使用 Cookie 收集您的信息。</li>
                    <li>3. 我們必定會合符私隱政策的規定，有機會向母公司、附屬公司及/或關聯公司分享您的個人資料，或委託管理相關個人資料。我們不會向非關聯公司出租、出售或分享您的個人資料。</li>
                    <li>4. 我們亦有機會向可靠的合作夥伴包括:社交媒體及搜尋引擎提供資料，如有需要被委託方將會按照保密協議規定代表我們或與我們共同處理您的個人資料及維護我們的資料庫。</li>
                    <li>5. 我們有機會因業務運作需要，將您的個人資料放存在香港以外的地方處理，並在這種​​情況下，定必會遵照私隱條例保護您的個人資料。</li>
                    <li>6. 如有法庭傳票或命令、法律程序或查證違反當地法律規定行動，需要網站內的個人資料，我們絕對會提供任何網站內的資料。</li>
                    <li>7. 我們會採取合理措施保護您的個人資料，若有任何資料遺失或外洩，而令您帶來任何損失或賠償，我們均不會承擔任何責任。</li>
                </ol>

                <h2>聯絡我們</h2>
                如對私隱政策有任何疑問，請從以下途徑聯絡我們。      <br />
                電話：(852) 2174 2800      <br />
                最後更新日期：2024年2月29日


            </div>
            <Footer />
        </div>
        // <div class="p-4 grid grid-rows-3 grid-flow-col gap-4" style={{ fontSize: 14, color: "#000000", backgroundColor: '#FAFAFA' }}>
        //     <h1>私隱政策</h1>

        //     瀏覽Lumenis BE(HK) Limited 以下統稱為「我們」、「我們的」、「本公司」)的網站和填寫網站登記表格前，請先詳細閱讀以下與使用您個人資料相關的條款和細則。並請同意及承諾不允許他人使用您的身份及不違反法律法規。

        //     私隠政策只適用於由網站所取得的個人資料，包括但不限於登記內容、資料查詢，以及用於識別個人身份的資料，例如姓名、電話、電郵地址、IP資料等。此政策將不時進行修訂。如在我們修訂本私隱政策後繼續使用網站，即代表您接受此等修訂，請定期查閱以了解最新版本資訊。

        //     在使用個人資料方面，我們將遵守私隱政策中的相關規定，詳情如下。
        //         1. 我們會將網站收集的資訊用作統計數據，例如衡量網站表現或額外使用分析工具作統計數據)。全部數據不包含可識別個人身份的數據，也不會用作收集個人資料。
        //         2. 我們會使用 Cookie 收集個人資料，您亦可以選擇拒絕使用 Cookie 收集您的信息。
        //         3.我們必定會合符私隱政策的規定，有機會向母公司、附屬公司及/或關聯公司分享您的個人資料，或委託管理相關個人資料。我們不會向非關聯公司出租、出售或分享您的個人資料。
        //         4.我們亦有機會向可靠的合作夥伴包括:社交媒體及搜尋引擎提供資料，如有需要被委託方將會按照保密協議規定代表我們或與我們共同處理您的個人資料及維護我們的資料庫。
        //         5.我們有機會因業務運作需要，將您的個人資料放存在香港以外的地方處理，並在這種​​情況下，定必會遵照私隱條例保護您的個人資料。
        //         6.如有法庭傳票或命令、法律程序或查證違反當地法律規定行動，需要網站內的個人資料，我們絕對會提供任何網站內的資料。
        //         7. 我們會採取合理措施保護您的個人資料，若有任何資料遺失或外洩，而令您帶來任何損失或賠償，我們均不會承擔任何責任。

        //     <h2>聯絡我們</h2>
        //     如對私隱政策有任何疑問，請從以下途徑聯絡我們。
        //     電話：(852) 2174 2800
        //     最後更新日期：2024年2月29日

        // </div>
    );
}
