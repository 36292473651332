import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, Breadcrumb, Radio, InputNumber, Select, Layout, Menu, Avatar, Carousel, Card, Button, theme, Row, Col, Drawer, Image, Anchor } from 'antd';
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import ServiceCenter from "../../components/serviceCenter";
import TermAndCondition from "../../components/termAndCondition";
import homeSection2 from '../../Assest/home-section2.svg';
import bannerHomePage from '../../Assest/bannerHomePage.svg';
import click from '../../Assest/click.svg';
import stellar from '../../Assest/Stellar.svg';
import splendoor from '../../Assest/SPLENDOR.svg';
import mask from '../../Assest/Mask.svg';
import homeNextIcon from '../../Assest/home_next.png';

import homeBanner from '../../Assest/home_banner.png';


import fdace from '../../Assest/fdace.png';
import homebanner from '../../Assest/homebanner.png';

import twoLayer from '../../Assest/two_layer.svg';
import _ from 'lodash';
import {
    HomeOutlined,
    UserOutlined,
    EditOutlined, EllipsisOutlined,
    SettingOutlined,
    MenuOutlined
} from '@ant-design/icons';
const { Meta } = Card;
const { TextArea } = Input;

const { Header, Content, Footer } = Layout;
const Equiryform = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [visible, setVisible] = useState(false);
    const [banner, setBanner] = useState([]);
    const [hotProduct, setHotProduct] = useState([]);
    const [newProduct, setNewProduct] = useState([]);
    const [argreementOption, setArgreementOption] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };


    const onClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        // addPosts();
    }, []);

    const onFinish = (values) => {
        if (argreementOption) {
            addPosts(values);
            return;
        }

        alert("請確認私隱政策");
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onCheckChange = (e) => {
        setArgreementOption(e.target.checked)
    };
    const onChange = (e) => {
        console.log(e);
    };

    const addPosts = async (values) => {
        const formData = new FormData();
        formData.append("company_name", values.company_name);
        formData.append("contact_name", values.contact_name);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("content", values.content);
        formData.append("equipment", values.equipment);
        formData.append("area_code", values.area_code);
        await fetch('http://45.76.199.167/insertEnquiry.php', {
            method: 'POST',
            body: formData,
            //    body: JSON.stringify({
            //       company_name: "1",
            //       contact_name: "2",
            //       phone: 3,
            //       email: 4,
            //       content:" 5",
            //       equipment:" 6",
            //       area_code: 7,
            //    }),
            //    headers: { 'Content-Type': 'application/json' },
            //    headers: {
            //       'Content-type': 'application/json; charset=UTF-8',
            //    },
        })
            .then((response) => response.json())
            .then((data) => {
                alert("Sent ! We will contact you later.")
                window.location.reload();
                console.log("data", data)
            })
            .catch((err) => {
                console.log("this", err.message);
            });
    };

    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Layout>
            <AppHeader />
            <Content
                className="site-layout"
                style={{
                    backgroundColor: "#FFFFFF",
                    padding: '0 20px',
                }}
            >
                <Row>
                    <Col xs={24} sm={24}>
                        <span style={{ color: '#777777', fontSize: 12 }}>BUSINESS ENQUIRIES</span>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24}>
                        <span style={{ fontWeight: 'bold', fontSize: 30 }}>業務查詢</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} style={{ marginTop: 10, marginBottom: 16 }}>
                        <span >商業客戶可填妥下表，專人將於三個工作天內與你聯絡</span>
                    </Col>
                </Row>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row>
                        <Col xs={24} sm={24} >
                            <Form.Item
                                label=""
                                name="company_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your company name!',
                                    },
                                ]}
                            >
                                <Input placeholder="公司名稱" style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} >
                            <Form.Item
                                label=""
                                name="contact_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your contact name!',
                                    },
                                ]}
                            >
                                <Input placeholder="聯絡人名稱" style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={7} sm={7}>
                            <Form.Item
                                label=""
                                name="area_code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your area code!',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                                    showSearch
                                    placeholder="電話區號"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    // onSearch={onSearch}
                                    filterOption={filterOption}
                                    options={[
                                        {
                                            value: '852',
                                            label: '852',
                                        },
                                        {
                                            value: '86',
                                            label: '86',
                                        },
                                        {
                                            value: '853',
                                            label: '853',
                                        }
                                        
                                    ]}
                                />
                                {/* <Input placeholder="電話區號" /> */}
                            </Form.Item>
                        </Col>

                        <Col xs={16} sm={16} style={{ marginLeft: 10 }}>
                            <Form.Item
                                label=""
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your phone!',
                                    },
                                ]}
                            >
                                <InputNumber placeholder="電話號碼" style={{ width: '100%', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} >
                            <Form.Item
                                label=""
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input placeholder="電郵" style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none' }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} >
                            <span style={{ color: "#777777" }}>你想查詢的儀器 (可選多項)</span>
                            <Form.Item
                                label=""
                                name="equipment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose your equipment!',
                                    },
                                ]}
                            >
                                <Checkbox.Group >
                                    <Checkbox value="AcuPulse">AcuPulse</Checkbox>
                                    <Checkbox value="GeneoX">Geneo X</Checkbox>
                                    <Checkbox value="LegendProPlus">Legend Pro Plus</Checkbox>
                                    <Checkbox value="M22">Splendor X</Checkbox>
                                    <Checkbox value="StellarM22">Stellar M22</Checkbox>
                                    <Checkbox value="UltraPulseAlpha">UltraPulse Alpha</Checkbox>
                                    <Checkbox value="Others">Others</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} >
                            <Form.Item
                                label=""
                                name="content"
                            >
                                <TextArea placeholder="諮詢內容(如適用)" allowClear style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none'}} onChange={onChange} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} >
                            <Form.Item
                                name="remember"
                                valuePropName=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please check your agreement !',
                                    },
                                ]}
                            >
                                <Checkbox onChange={onCheckChange} className="foo">本人已經閱讀並同意私隱政策</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} >
                            <Form.Item>
                                <Row style={{ justifyContent: 'center', display: 'flex'}}>
                                    <Col xs={24} sm={24} style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{ backgroundColor: "#333333", margin: 'auto', width: '50%', height: 53 }} type="primary" htmlType="submit">
                                            確認
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Content>
            <AppFooter/>
        </Layout>
    );
};

export default Equiryform;