"use client";


import { useEffect, useState } from "react";
import { Breadcrumb, Layout, Menu, Avatar, Carousel, Card, Button, theme, Row, Col, Drawer, Image, Anchor } from 'antd';
import Header from "../components/Header";
import Footer from "../components/Footer";


export default function disclaimer() {
    return (
        <div
            style={{ color: "#333333", fontFamily: "Noto Sans HK" }}
            className="[&>*]:w-full w-full"
        >
            <Header />
            <div style={{ margin: 'auto', width: '90%'}}>
                <h1>Disclaimer</h1>
                <p><span>1. AGREEMENT BETWEEN USER AND WEBSITE</span></p>
                 <p><br /></p>
                <p><span  >These Terms of Use govern the access to and use of this website (the &ldquo;Website&rdquo;), which is owned and operated by Lumenis Be Ltd. or any of its subsidiaries (&ldquo;Lumenis&ldquo;). Your use of this Website constitutes your agreement and acceptance without modification of the notices, terms and conditions set forth herein. If you do not agree and accept without modification the Terms of Use set forth herein, do not use this Website. Other than these Terms of Use, Lumenis will not enter into any agreement with you or obligation to you through this Website, and no attempt to create such an agreement or obligation will be effective. This Website does not provide medical advice. If you think you may have a medical emergency, call your doctor or the medical emergency number in your area immediately.</span></p>
                 <p><br /></p>
                <p><span  >2. MODIFICATION OF THESE TERMS OF USE</span></p>
                 <p><br /></p>
                <p><span  >Lumenis reserves the right, in its discretion and without notice at any time, to change, modify, add, or remove portions of the Terms of Use at any time, provided that if we decide to modify these Terms of Use, we will update the Effective Date and the Last Modified Date. Please check these Terms of Use periodically for changes. Your continued use of this Website following any updates to these Terms of Use will mean you accept the Terms of Use as then in effect, including any subsequent additions, changes or modifications to the terms and conditions expressed herein.</span></p>
                 <p><br /></p>
                <p><span  >3. INTENDED AUDIENCE</span></p>
                 <p><br /></p>
                <p><span  >This Website is intended for use only in jurisdictions where the Lumenis&rsquo; products can be used by and promoted to the general public, including physicians, associated medical personnel, patients, health clinics and hospitals, Lumenis&rsquo; clients and its shareholders. This Website is not intended for use by children under the age of 18. Please refer to Lumenis&rsquo; Privacy Statement regarding use of this Website by children.</span></p>
                 <p><br /></p>
                <p><span  >4. PRIVACY</span></p>
                 <p><br /></p>
                <p><span  >It is our policy to respect the privacy of all guests and registered users of this Website according to the terms of our Privacy Statement. You are responsible for any use or disclosure of your information, including personal and health information that you make on this Website, and any use or disclosure of this information on the Website is made at your own risk (subject to any mandatory legal obligation to the extent applicable to us). Please consider carefully what information you choose to submit via the Website, and review our Privacy Statement. If you wish to share via the Website any third party personal information, you must obtain the prior consent of the relevant person.</span></p>
                 <p><br /></p>
                <p><span  >With respect to all information you elect to post to publicly accessible areas of this Website, you agree that Lumenis has the right to use, reproduce, copy, modify, publish, distribute, perform and display such information, in whole or part, publicly; provided that such use is in accordance with the terms of Lumenis&rsquo; Privacy Statement. You acknowledge and agree that Lumenis may preserve information, and may also disclose information if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: comply with legal process; enforce the Terms of Use; respond to claims that any information violates the rights of third-parties; or protect the rights, property, or personal safety of Lumenis&rsquo; business, employees, affiliated or related entities, and the public. Lumenis may also otherwise share personal information in accordance with the Privacy Statement.</span></p>
                 <p><br /></p>
                <p><span  >5. WEBSITE IS FOR INFORMATIONAL PURPOSES ONLY</span></p>
                 <p><br /></p>
                <p><span  >Lumenis does not practice medicine or provide medical advice or diagnosis. Lumenis provides this Website for informational purposes only. No information contained in the Website is intended or should be construed to provide medical advice or diagnosis, to establish a patient-physician relationship, or to serve as a substitute for talking to your health care provider. It is important that you rely only on the advice of your own physician or other suitable healthcare provider to advise you on your specific situation. The information provided by this Website or by Lumenis personnel should not be a substitute for professional medical advice or care. Lumenis&rsquo; professionals cannot give any medical advice and cannot diagnose medical problems.</span></p>
                 <p><br /></p>
                <p><span  >6. APPLICABLE LAWS</span></p>
                 <p><br /></p>
                <p><span  >Your use of the Website may be subject to international, European Union, national, federal, state and local laws and regulations, including, but not limited to, laws regulating the privacy and security of health information, professional licensure, and the practice of medicine. You are solely responsible for your use of this Website being in compliance with all applicable international, European Union, national, federal, state and local laws and regulations.</span></p>
                 <p><br /></p>
                <p><span  >7. LINKS TO THIRD PARTY SITES</span></p>
                 <p><br /></p>
                <p><span  >The Website may contain links to other Websites (&ldquo;Linked Sites&ldquo;). Lumenis does not control, review or monitor the Linked Sites and is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. The Linked Sites may contain information about uses of Lumenis products or services that have not been approved in a particular jurisdiction. &nbsp;Lumenis is not responsible for webcasting or any other form of transmission received from any Linked Site. Lumenis is providing these links to you as a convenience only, and the inclusion of any link does not imply endorsement by Website of the site or any association with its operators. Your use of Linked Sites is subject to the terms of use of these sites, including their privacy policies.</span></p>
                 <p><br /></p>
                <p><span  >8. COOKIES</span></p>
                 <p><br /></p>
                <p><span  >Accessing the Website will create a &ldquo;cookie&rdquo;. A cookie is a small amount of data which is transferred to your browser by a web server. Cookies may be used to improve user experience as well as for marketing purposes. For more information, please refer to our Privacy Statement.</span></p>
                 <p><br /></p>
                <p><span  >9. PROHIBITED USES</span></p>
                 <p><br /></p>
                <p><span  >As a condition of your use of the Website, you must not, by yourself or with others:</span></p>
                 <p><br /></p>
                <p><span  >Use the Website for any purpose that is unlawful, immoral, or prohibited by these terms, conditions, and notices.</span></p>
                <p><span  >Use the Website in any manner which could damage, disable, overburden, or impair the Website or interfere with any other party&rsquo;s use and enjoyment of the Website.</span></p>
                <p><span  >Use or access the Website in order to build a competitive product or service or for purposes of monitoring the availability, performance or functionality of Lumenis products, or for any other benchmarking or competitive purposes.</span></p>
                <p><span  >Engage in any form of scanning, scraping, crawling, probing, robotic navigating, bulk extracting or hacking of the Website or its content.</span></p>
                <p><span  >Obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Website.</span></p>
                <p><span  >10. MATERIALS PROVIDED TO WEBSITE OR POSTED AT ANY WEBSITE</span></p>
                 <p><br /></p>
                <p><span  >Any feedback, comments, ideas, know-how, materials and other information (collectively, &ldquo;Submissions&rdquo;) you submit through this Website, will be considered non-personal, non-confidential and non-proprietary (other than your personal information that is subject to the Privacy Statement). &nbsp;By posting, uploading, inputting, providing or submitting your Submission you hereby grant Lumenis, its affiliated companies and necessary sublicensees an unrestricted, royalty-free, irrevocable license to use reproduce, display, perform, modify, transmit and distribute them in any medium, and agree that Lumenis is free to use them &nbsp;in the course of operating its business and otherwise carrying out its commercial activities.</span></p>
                 <p><br /></p>
                <p><span  >No compensation will be paid with respect to the use of your Submission, as provided herein. Lumenis is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in Lumenis&rsquo; sole discretion.</span></p>
                 <p><br /></p>
                <p><span  >By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</span></p>
                 <p><br /></p>
                <p><span  >11. LIABILITY DISCLAIMER</span></p>
                 <p><br /></p>
                <p><span  >THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE WEBSITE MAY INCLUDE INACCURACIES OR ERRORS. LUMENIS AND/OR OTHER THIRD PARTIES ON ITS BEHALF MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE WEBSITE AT ANY TIME. ADVICE RECEIVED VIA THE WEBSITE SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.</span></p>
                 <p><br /></p>
                <p><span  >LUMENIS AND/OR OTHER THIRD PARTIES ON ITS BEHALF MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE WEBSITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED &ldquo;AS IS&rdquo; WITHOUT WARRANTY OR CONDITION OF ANY KIND, AND SPECIFICALLY EXCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</span></p>
                 <p><br /></p>
                <p><span  >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL LUMENIS AND/OR OTHER THIRD PARTIES ON ITS BEHALF BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE WEBSITE, WITH THE DELAY OR INABILITY TO USE THE WEBSITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF THE WEBSITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF LUMENIS OR ANY OTHER THIRD PARTY ON ITS BEHALF HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE WEBSITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE.</span></p>
                 <p><br /></p>
                <p><span  >12. INDEMNIFICATION</span></p>
                 <p><br /></p>
                <p><span  >You agree to indemnify, defend, and hold harmless Lumenis, its officers, directors, employees, agents, licensors and suppliers from and against all losses, expenses, damages and costs, including reasonable attorneys&rsquo; fees, resulting from any violation by you of these Terms of Use or applicable law.</span></p>
                 <p><br /></p>
                <p><span  >13. JURISDICTIONAL ISSUES AND GOVERNING LAW</span></p>
                 <p><br /></p>
                <p><span  >This Website is controlled and operated by Lumenis from a server that resides in Tel-Aviv, Israel. You agree that this Terms of Use Policy and your use of this Website are governed by the procedural and substantive laws of the State of Tel-Aviv, Israel without regard to any conflict of laws provisions. You hereby consent to the exclusive jurisdiction and venue of the courts in Tel-Aviv, Israel in all disputes arising out of, relating to, or concerning this Website and/or the Terms of Use in which this Website and or the Terms of Use is an issue of material fact, or in which this Website and/or the Terms of Use is referenced in a paper filed in a court. Those who choose to access this Website from other locations explicitly accept the jurisdiction of the Israel and its competent courts, do so on their own initiative, and are responsible for establishing the usability or correctness of any information or materials under any or all jurisdictions and the compliance of that information or material with local laws, if and to the extent local laws are applicable.</span></p>
                 <p><br /></p>
                <p><span  >Lumenis makes no representations that materials on this Website are appropriate or available for use in any particular jurisdiction.</span></p>
                 <p><br /></p>
                <p><span  >14. CONTENT ON WEBSITE</span></p>
                 <p><br /></p>
                <p><span  >Any reference made by this Website to any specific commercial product, process, or service (or provider of such product, process or service) other than of Lumenis, by trade name, trademark, hyperlink, or otherwise, does not constitute or imply an endorsement or recommendation by Lumenis.</span></p>
                 <p><br /></p>
                <p><span  >15. COPYRIGHT AND TRADEMARK NOTICES</span></p>
                 <p><br /></p>
                <p><span  >All contents of the Website are owned or licensed by Lumenis. &nbsp;Modification of any Lumenis&rsquo; materials on this Website or use of such materials for any other purpose is a violation of Lumenis&rsquo; copyright and other proprietary rights. In accordance with these Terms of Use, the use of any such Lumenis&rsquo; materials on any other Website or networked computer environment is prohibited.</span></p>
                 <p><br /></p>
                <p><span  >The names of actual companies and products mentioned herein may be the trademarks of their respective owners.</span></p>
                 <p><br /></p>
                <p><span  >Any rights not expressly granted herein are reserved.</span></p>
                 <p><br /></p>
                <p><span  >16. REQUEST TO OPT OUT OF COMMUNICATIONS FROM LUMENIS</span></p>
                 <p><br /></p>
                <p><span  >If you wish to opt out of future communications from Lumenis, please contact us at privacy@lumenis.com and indicate which communications you no longer wish to receive.</span></p>
                 <p><br /></p>
                <p><span  >17. RIGHT TO DENY OR LIMIT ACCESS</span></p>
                 <p><br /></p>
                <p><span  >Lumenis reserves the right to limit or revoke any user&rsquo;s access to this Website in its sole discretion, at any time, and for any reason, including, but not limited to any violation of these Terms of Use or in the event of technical difficulties associated with the Website.</span></p>
                 <p><br /></p>
                <p><span  >18. SEVERABILITY</span></p>
                 <p><br /></p>
                <p><span  >If any provision of these Terms of Use shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.</span></p>
                 <p><br /></p>
                <p><span  >19. NO LEGAL RELATIONSHIP</span></p>
                 <p><br /></p>
                <p><span  >You agree that no joint venture, partnership, employment, or agency relationship exists between you and Lumenis as a result of the Terms of Use or your use of the Website.</span></p>
                 <p><br /></p>
                <p><span  >20. ENTIRE AGREEMENT</span></p>
                 <p><br /></p>
                <p><span  >These Terms of Use serve as the entire agreement between Lumenis and users of this Website relating to the subject matter herein and may not be modified except as provided above or in a writing that has been signed all parties sought to be bound thereby. Any alleged waiver of any breach of the Terms of Use shall not be deemed to be a waiver of any future breach.</span></p>
                 <p><br /></p>
                <p><span  >21. FURTHER INFORMATION</span></p>
                 <p><br /></p>
                <p><span  >For further information or to contact us with questions, concerns, or comments, you may write, call, fax, or e-mail us as follows:</span></p>
                <p><span  ><br/></span><span  ><br /></span><span  >Lumenis Be Ltd.</span><span  ><br /></span><a href="mailto:privacy@lumenis.com"><u><span>privacy@lumenis.com</span></u><span  ><br /></span></a><span  >Hakidma 9, Yokneam, Israel</span><span  ><br /></span><span  >Office +972.495.99100</span><span  ><br /></span><span  >Fax +972.490.99486</span><span  ><br /></span><span  >www.lumenis.com&nbsp;</span></p>

            </div>
            <Footer />
        </div>
        // <div class="p-4 grid grid-rows-3 grid-flow-col gap-4" style={{ fontSize: 14, color: "#000000", backgroundColor: '#FAFAFA' }}>
        //     <h1>私隱政策</h1>

        //     瀏覽Lumenis BE(HK) Limited 以下統稱為「我們」、「我們的」、「本公司」)的網站和填寫網站登記表格前，請先詳細閱讀以下與使用您個人資料相關的條款和細則。並請同意及承諾不允許他人使用您的身份及不違反法律法規。

        //     私隠政策只適用於由網站所取得的個人資料，包括但不限於登記內容、資料查詢，以及用於識別個人身份的資料，例如姓名、電話、電郵地址、IP資料等。此政策將不時進行修訂。如在我們修訂本私隱政策後繼續使用網站，即代表您接受此等修訂，請定期查閱以了解最新版本資訊。

        //     在使用個人資料方面，我們將遵守私隱政策中的相關規定，詳情如下。
        //         1. 我們會將網站收集的資訊用作統計數據，例如衡量網站表現或額外使用分析工具作統計數據)。全部數據不包含可識別個人身份的數據，也不會用作收集個人資料。
        //         2. 我們會使用 Cookie 收集個人資料，您亦可以選擇拒絕使用 Cookie 收集您的信息。
        //         3.我們必定會合符私隱政策的規定，有機會向母公司、附屬公司及/或關聯公司分享您的個人資料，或委託管理相關個人資料。我們不會向非關聯公司出租、出售或分享您的個人資料。
        //         4.我們亦有機會向可靠的合作夥伴包括:社交媒體及搜尋引擎提供資料，如有需要被委託方將會按照保密協議規定代表我們或與我們共同處理您的個人資料及維護我們的資料庫。
        //         5.我們有機會因業務運作需要，將您的個人資料放存在香港以外的地方處理，並在這種​​情況下，定必會遵照私隱條例保護您的個人資料。
        //         6.如有法庭傳票或命令、法律程序或查證違反當地法律規定行動，需要網站內的個人資料，我們絕對會提供任何網站內的資料。
        //         7. 我們會採取合理措施保護您的個人資料，若有任何資料遺失或外洩，而令您帶來任何損失或賠償，我們均不會承擔任何責任。

        //     <h2>聯絡我們</h2>
        //     如對私隱政策有任何疑問，請從以下途徑聯絡我們。
        //     電話：(852) 2174 2800
        //     最後更新日期：2024年2月29日

        // </div>
    );
}
