"use client";

import { useEffect, useRef, useState } from "react";
import { DownArrow } from "../svg/arrow";

export default function DropdownCustom({ children, title, className }) {
  const [isHidden, setHidden] = useState(true);
  const headRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    contentRef.current.style.marginTop =
      contentRef.current.clientHeight * -2 + "px";
  }, [contentRef]);

  function handleClick() {
    const currentHidden = isHidden;
    if (!headRef.current || !contentRef.current) return;
    if (currentHidden) {
      contentRef.current.style.marginTop = "0px";
      headRef.current.setAttribute("expand", "true");
    } else {
      contentRef.current.style.marginTop =
        contentRef.current.clientHeight * -1 + "px";
      headRef.current.setAttribute("expand", "false");
    }
    setHidden(!currentHidden);
  }

  return (
    <div className={className}>
      <div
        className="expand-head"
        ref={headRef}
        expand="false"
        onClick={() => handleClick()}
      >
        <div className="font-medium">{title}</div>
        <DownArrow className={"revert"} />
      </div>
      <div className="expand-container">
        <div className="expand-body font-light" ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
}
