export const Phone = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_146_1764)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.6493 0.447192C1.8024 0.29432 1.98624 0.175731 2.18865 0.0992837C2.39105 0.0228365 2.60738 -0.00972316 2.82332 0.00376252C3.03926 0.0172482 3.24986 0.0764712 3.44118 0.177507C3.6325 0.278543 3.80016 0.419084 3.93305 0.589817L5.50367 2.60757C5.79155 2.97769 5.89305 3.45982 5.7793 3.91482L5.30067 5.83107C5.27593 5.93032 5.27726 6.03428 5.30455 6.13286C5.33184 6.23144 5.38416 6.3213 5.45642 6.39369L7.6063 8.54357C7.67878 8.61598 7.76878 8.66838 7.86753 8.69567C7.96628 8.72297 8.07042 8.72422 8.1698 8.69932L10.0852 8.22069C10.3097 8.16455 10.5441 8.16019 10.7705 8.20794C10.997 8.25569 11.2097 8.3543 11.3924 8.49632L13.4102 10.0661C14.1355 10.6304 14.202 11.7023 13.5528 12.3507L12.648 13.2554C12.0005 13.9029 11.0328 14.1873 10.1307 13.8697C7.82171 13.0573 5.72529 11.7354 3.99692 10.0022C2.2638 8.27408 0.941956 6.17797 0.129424 3.86932C-0.187326 2.96807 0.0970488 1.99944 0.744549 1.35194L1.6493 0.447192Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_146_1764">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
