export const DownArrow = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      id="down-arrow"
      fill="#5F95E7"
      className={className}
    >
      <path d="M22.782 13.8 17 19.582 11.218 13.8a1 1 0 0 0-1.414 1.414L16.29 21.7a.992.992 0 0 0 .71.292.997.997 0 0 0 .71-.292l6.486-6.486a1 1 0 0 0-1.414-1.414z"></path>
    </svg>
  );
};

export const UpArrow = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      id="up-arrow"
      fill="#5F95E7"
      className={className}
    >
      <path d="M11.218 20.2L17 14.418l5.782 5.782a1 1 0 0 0 1.414-1.414L17.71 12.3a.997.997 0 0 0-.71-.292.997.997 0 0 0-.71.292l-6.486 6.486a1 1 0 0 0 1.414 1.414z"></path>
    </svg>
  );
};

export const UpDownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      id="up-arrow"
      fill="#0276FF"
    >
      <path d="M 11.218 15 L 17 9.218 l 5.782 5.782 a 1 1 0 0 0 1.414 -1.414 L 17.71 7.1 a 0.997 0.997 0 0 0 -0.71 -0.292 a 0.997 0.997 0 0 0 -0.71 0.292 l -6.486 6.486 a 1 1 0 0 0 1.414 1.414 z"></path>
      <path d="M 22.782 19 L 17 24.782 L 11.218 19 a 1 1 0 0 0 -1.414 1.414 L 16.29 26.9 a 0.992 0.992 0 0 0 0.71 0.292 a 0.997 0.997 0 0 0 0.71 -0.292 l 6.486 -6.486 a 1 1 0 0 0 -1.414 -1.414 z"></path>
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75064 9C4.81389 9 4.87863 8.97315 4.92718 8.92102C5.02427 8.81676 5.02427 8.64617 4.92718 8.54191L1.6039 4.97354L4.87863 1.4573C4.97573 1.35305 4.97573 1.18245 4.87863 1.07819C4.78154 0.973938 4.62266 0.973937 4.52556 1.07819L1.07282 4.78399C0.975727 4.88824 0.975727 5.05884 1.07282 5.1631L4.57264 8.92102C4.62265 8.97473 4.68591 9 4.75064 9Z"
        fill="#333333"
        stroke="#333333"
      />
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.24936 1C1.18611 1 1.12137 1.02685 1.07282 1.07898C0.975727 1.18324 0.975727 1.35384 1.07282 1.45809L4.3961 5.02646L1.12137 8.5427C1.02427 8.64695 1.02427 8.81755 1.12137 8.92181C1.21846 9.02606 1.37734 9.02606 1.47444 8.92181L4.92718 5.21601C5.02427 5.11176 5.02427 4.94116 4.92718 4.8369L1.42736 1.07898C1.37735 1.02527 1.31409 1 1.24936 1Z"
        fill="#333333"
        stroke="#333333"
      />
    </svg>
  );
};
