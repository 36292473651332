import React, { useState, useEffect } from "react";
import { Breadcrumb, Layout, Menu, Avatar, Carousel, Card, Button, theme, Row, Col, Drawer, Image, Anchor } from 'antd';
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import ServiceCenter from "../../components/serviceCenter";
import TermAndCondition from "../../components/termAndCondition";
import homeSection2 from '../../Assest/home-section2.svg';
import bannerHomePage from '../../Assest/bannerHomePage.svg';
import click from '../../Assest/click.svg';
import stellar from '../../Assest/Stellar.svg';
import splendoor from '../../Assest/SPLENDOR.svg';
import mask from '../../Assest/Mask.svg';
import homeNextIcon from '../../Assest/home_next.png';

import homeBanner from '../../Assest/home_banner.png';


import fdace from '../../Assest/fdace.png';
import homebanner from '../../Assest/homebanner.png';

import twoLayer from '../../Assest/two_layer.svg';
import _ from 'lodash';
import {
  HomeOutlined,
  UserOutlined,
  EditOutlined, EllipsisOutlined,
  SettingOutlined,
  MenuOutlined
} from '@ant-design/icons';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-9XTPFNX5EE"; // your Measurement ID
const { Meta } = Card;

const { Header, Content, Footer } = Layout;
const Home = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [visible, setVisible] = useState(false);
  const [banner, setBanner] = useState([]);
  const [hotProduct, setHotProduct] = useState([]);
  const [newProduct, setNewProduct] = useState([]);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "home" });
  }, [])


  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <Layout>
      {/* <Anchor affix={false}>
          <AppHeader/>
       </Anchor> */}
      <AppHeader />

      <Content
        className="site-layout"
        style={{
          backgroundColor: "#FFFFFF"
          // padding: '0 50px',
        }}
      >

        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={0} style={{
          }}>
            <Carousel autoplay>
              <div>
                <img
                  src={"/images/p0_img1.png"}
                  alt="Banner"
                  style={{ width: '100%', height: '100%', padding: 0 ,objectFit: 'cover' }}
                />
              </div>
              <div>
                <img
                  src={"/images/p0_img1-b.png"}
                  alt="Banner"
                  style={{ width: '100%', height: '100%', padding: 0 , objectFit: 'cover' }}
                />
              </div>
              <div>
                <img
                  src={"/images/p0_img1-c.png"}
                  alt="Banner"
                  style={{ width: '100%', height: '100%', padding: 0 , objectFit: 'cover' }}
                />
              </div>
            </Carousel>
          </Col>

          <Col xs={0} sm={0} md={24} lg={24} style={{
          }}>
            <Carousel autoplay>
              <div>
                <img
                  src={"/images/p0_img1.png"}
                  alt="Banner"
                  // style={{ width: '100%', height: '100%', padding: 0 ,objectFit: 'cover' }}
                />
              </div>
              <div>
                <img
                  src={"/images/p0_img1-b.png"}
                  alt="Banner"
                  // style={{ width: '100%', height: '100%', padding: 0 , objectFit: 'cover' }}
                />
              </div>
              <div>
                <img
                  src={"/images/p0_img1-c.png"}
                  alt="Banner"
                  // style={{ width: '100%', height: '100%', padding: 0 , objectFit: 'cover' }}
                />
              </div>
            </Carousel>
          </Col>
        </Row>

        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={0} style={{
          }}>
            <img
              src={"/images/p0_img4.png"}
              alt="Banner"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <Row style={{
              position: 'absolute',
              top: '20%',
              left: '4%',
              // top: '50%',
              // left: '40%',
              // transform: 'translate(-50%, -50%)',
              // width: '100%', // Adjust the width as needed'
            }}>
              <Col xs={24} sm={24} md={0} style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: 'black'
              }} >
                領先醫美科技
              </Col>

              <Col xs={24} sm={24} md={0} style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: 'black'
              }} >
                引領世界醫美科研
              </Col>

              <Col xs={24} sm={24} md={0} style={{
                marginTop: 10,
                fontSize: 16,
                color: '#333333'
              }} >
                Lumenis自1966年成立以來便致力研究光學技術，當中IPL光子嫩膚以及CO2疤痕激光更被視為業界的黃金基準，追求療效的同時兼顧安全，並一直突破頂尖科技，引領世界醫美科研至今。
              </Col>

            </Row>

            {/* </div> */}
          </Col>
        </Row>


        <Row gutter={[8, 16]} style={{
          padding: '10px 10px',
          backgroundColor: "#FFFFFF"
        }}>
          <Col xs={12} sm={12} md={12}>
            <Card
              style={{
                paddingTop: 4
                // width: 200,
              }}
              cover={
                <img
                  style={{ padding: 0, margin: 0 }}
                  src={"/images/p0_img3.png"}
                />
              }
            >
              <Row>
                <Col xs={24} sm={24} md={24} >
                  <span style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'left' }}>Stellar M22 IPL <br/> 強效脈衝光</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={0}>
                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        alt="click"
                        style={{
                          paddingTop: 5
                        }} 
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0} style={{
                      paddingTop: 0
                    }} >
                      <span style={{ fontSize: 10 }}>全球唯一專利OPT®技術光子嫩膚療程</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        alt="click"
                        style={{
                          paddingTop: 5
                        }} 
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0} style={{
                      paddingTop: 0
                    }} >
                      <span style={{ fontSize: 10 }}>MSP多重連續脈衝科技</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        alt="click"
                        style={{
                          paddingTop: 5
                        }} 
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0} style={{
                      paddingTop: 0
                    }} >
                      <span style={{ fontSize: 10 }}>ResurFX™無創分段煥膚激光</span>
                    </Col>
                  </Row>

                  <Row  onClick={() => window.location.href="/m22"}>
                    <Col xs={12} sm={12} md={0} style={{
                      paddingTop: 10
                    }} >
                      <span style={{ fontSize: 13, color: "#000000", fontWeight: 'bold' }}>了解更多</span>
                    </Col>
                    <Col xs={4} sm={4} md={0}>
                      <img
                        src={homeNextIcon}
                        alt="homeNextIcon"
                        style={{
                          paddingTop: 10
                        }} 
                      />
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={12}>
            <Card
              style={{
                paddingTop: 4
                // width: 200,
              }}
              cover={
                <img
                  style={{ padding: 0, margin: 0 }}
                  src={"/images/p0_img2.png"}
                  // src={splendoor}
                />
              }
            >
              <Row>
                <Col xs={24} sm={24} md={24} >
                  <span style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'left' }}>SPLENDOR X <br/> 激光脫毛</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={0}>
                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        style={{
                          paddingTop: 5
                        }} 
                        alt="click"
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0} style={{
                      paddingTop: 0
                    }} >
                      <span style={{ fontSize: 10 }}>全球首創雙波長BLEND X™激光技術 </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        style={{
                          paddingTop: 5
                        }} 
                        alt="click"
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0}>
                      <span style={{ fontSize: 10 }}>雙重冰感降溫系統</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        style={{
                          paddingTop: 5
                        }} 
                        alt="click"
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0}>
                      <span style={{ fontSize: 10 }}>專利方形激光脫毛機頭</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} sm={4} md={0} >
                      <img
                        src={click}
                        style={{
                          paddingTop: 5
                        }} 
                        alt="click"
                      />
                    </Col>
                    <Col xs={20} sm={20} md={0}>
                      <span style={{ fontSize: 10 }}>專利方形激光脫毛機頭</span>
                    </Col>
                  </Row>
                </Col>
                <Row  onClick={() => window.location.href="/stellar"}>
                    <Col xs={12} sm={12} md={0} style={{
                      paddingTop: 10
                    }} >
                      <span style={{ fontSize: 13, color: "#000000", fontWeight: 'bold' }}>了解更多</span>
                    </Col>
                    <Col xs={4} sm={4} md={0}>
                      <img
                      
                        src={homeNextIcon}
                        alt="homeNextIcon"
                        style={{
                          paddingTop: 10
                        }} 
                      />
                    </Col>
                  </Row>
              </Row>
            </Card>
          </Col>
        </Row>


        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={24} style={{
            // padding: '50px 50px',
            fontSize: 30,

          }}>
              <div className="mb-2 overflow-x-scroll customize-scrollbar rounded-xl">
            <img
              src={homeBanner}
              alt=""
              className="block max-w-[48rem] max-h-none"
            />
          </div>
            {/* <img
              src={bannerHomePage}
              alt="Banner"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            /> */}
          </Col>
        </Row>

        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={24} style={{
            // padding: '50px 50px',
            fontSize: 30,

          }}>
              <img
              src="../images/two-con.png"
              alt=""
              className="w-full -translate-y-1 z-0"
            />
          </Col>
        </Row>

        {/* <Row justify="space-around" align="middle" style={{
          backgroundColor: "#FFFFFF"
        }}>
          <Col xs={24} sm={24} md={24} style={{
            padding: '10px 20px',
            fontSize: 15,
            color: "#777777"
          }}>
            <span>2 Promises </span>
          </Col>

          <Col xs={24} sm={24} md={24} style={{
            padding: '10px 20px',
            fontSize: 30,
            fontWeight: 'bold',
            fontStyle:'oblique'
          }}>
              <span >2 / 大認證 </span>
          </Col>

          <Col xs={24} sm={24} md={0} style={{
            padding: '10px 20px',
          }}>
            <img
              width={"100%"}
              src={fdace}
            />
          </Col>

        </Row> */}

        <ServiceCenter />
        <TermAndCondition />

        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={24}>
            <AppFooter />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Home;