import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StellarPage from "./pages/stella";
import M22Page from "./pages/m22";
import Equiryform from "./pages/Equiryform/";
import Home from "./pages/Home/";
import PrivatePolicy from "./pages/privatePolicy";
import Disclaimer from "./pages/disclaimer";
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize('G-9XTPFNX5EE');

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/stellar" element={<StellarPage />} />
        <Route path="/m22" element={<M22Page />} />
        <Route path="/form" element={<Equiryform />} />
        <Route path="/privatePolicy" element={<PrivatePolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
App();
reportWebVitals();
