import { HomeFilled, ShoppingCartOutlined } from "@ant-design/icons";
import { Breadcrumb, Space, Layout, Menu, Button, theme, Row, Col, Drawer, Image, Anchor, Badge, Avatar, Grid, Divider } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import navLogo from "../../Assest/nav-logo.svg";
import mobileLogo from "../../Assest/mobile-logo.svg";
import globeLogo from "../../Assest/globe.svg";

const { Item } = Menu;
const { Header, Content, Footer } = Layout;

function AppHeader() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onMenuClick = (item) => {
    navigate(`/${item.key}`);
  };

  function MenuItem() {
    return (
      <div>
        <Row gutter={[8, 16]}>
          <Col xs={21} sm={21} md={0}>
            <Row>
              <Image
                preview = {false}
                onClick={()=> window.location.href="/"}
                src={navLogo}
              />
            </Row>
          </Col>
          <Col xs={3} sm={3} md={0}>
            <Row>
              <Button style={{ backgroundColor: "unset" }} type="primary" shape="circle">X</Button>
            </Row>
          </Col>
        </Row>

        <Divider style={{ padding: 0, backgroundColor: '#2f3030' }} />
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Row>
              <Col xs={15} sm={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Image
                  preview = {false}
                  style={{ paddingTop: 6 }}
                  src={globeLogo}
                />
              </Col>
              <Col xs={9} sm={8}>
                <p style={{ paddingTop: 14, fontSize: 14, textAlign: 'right' }}> <span>中文</span> | <span onClick={()=> window.location.href="https://lumenis.com/"}>English</span></p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Menu.Item style={{ backgroundColor: '#000000', color: "#999999", padding: 0 }} key="1" onClick={() => window.location.href = "/m22"}>
          <span style={{ fontWeight: 'bold' }}>Stellar</span>
          <span style={{ fontWeight: 'normal', color: '#' }}> M22 </span>
          <sup style={{ fontSize: 7 }}>TM</sup>
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#000000', color: "#999999", padding: 0 }} key="2" onClick={() => window.location.href = "/stellar"}>
          <span style={{ fontWeight: 'bold' }}>SPLENDOR</span>
          <span style={{ fontWeight: 'normal' }}> X </span>
        </Menu.Item>
{/* 
        <Menu.Item style={{ color: "#999999", padding: 0 }} key="3" onClick={() => window.location.href = "/form"}>
          <span style={{ fontWeight: 'bold' }}>業務查詢</span>
        </Menu.Item> */}

        <Divider style={{ padding: 0, background: '#2f3030' }} />

        <Row gutter={[8, 16]}>
          <Col xs={24}>
            <Row>
              <Col xs={24} ><p style={{ fontSize: 14 }}>客户服務熱線</p> </Col>
              <Col xs={24} ><p style={{ color: '#999999', fontSize: 20, marginTop: 20 }}>(852) 2174 2800</p> </Col>
            </Row>
          </Col>

          <Col xs={18}>
            <Row>
              <Col span={24} ><p style={{ fontSize: 14, marginTop: 20 }}>Whatsapps</p> </Col>
              <Col span={24} ><p style={{ color: '#999999', fontSize: 20, marginTop: 20 }}><a href="https://wa.me/85297114991" >(852) 9711 4991</a></p> </Col>
            </Row>
          </Col>

          <Col xs={6}>
            <Row>
                <img  onClick={() => window.location.href = "/form"} src="../images/p1_img1.png" />
            </Row>
          </Col>
        </Row>

        {/* <Row gutter={[8, 16]}>
          <Col xs={24}>
            <Row>
              <Col span={24} ><p style={{ fontSize: 14 }}>電郵</p> </Col>
              <Col span={24} ><p style={{ color: '#999999', fontSize: 20, marginTop: 16 }}>xxx@xxx.com</p> </Col>
            </Row>
          </Col>
        </Row> */}
      </div>
    )
  }
  return (
    <Header style={{ padding: 0, background: 'white' }}>
      <Anchor>
        <Row justify="space-between" align="middle" style={{ background: "white", marginLeft: -2 }}>
          <Col xs={0} sm={0} md={24}>
            <Image
              style={{ color: "white", marginLeft: 50}}
              width={200}
              preview = {false}
              src={mobileLogo}
            />
          </Col>

          <Col xs={0} sm={0} md={20} lg={20}>
            <Row>
              <Col xs={0} sm={0} md={0} lg={0}>
                <Menu theme="dark" style={{ background: "black", minHeight: 50, fontSize: 18, padding: "20px" }} mode="horizontal" >
                  <MenuItem />
                </Menu>
              </Col>

              <Col xs={0} sm={0} md={0} lg={0} style={{ padding: "20px 0px" }}>
                <Row>
                  <Col md={6} lg={6} >
                    <Badge  >
                      <Avatar onClick={() => window.location.href = "/login"} shape="square" icon={<UserOutlined />} style={{ width: '100%', fontSize: 30, textAlign: 'right' }} />
                    </Badge>
                  </Col>

                  <Col md={6} lg={6} >
                    <Button type="text"><Badge count={2} >
                      <Avatar onClick={() => window.location.href = "/shopping-cart"} shape="square" icon={<ShoppingCartOutlined />} style={{ width: '100%', fontSize: 30, textAlign: 'right' }} />
                    </Badge> </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={0} style={{ padding: "10px 10px" }}>
            <Row gutter={[16, 16]} align={"bottom"}>
              <Col xs={21} sm={21}>
                {/* <p style={{color: "white"}}> test2</p> */}
                <Image
                  preview = {false}
                  onClick={()=> window.location.href="/"}
                  style={{ marginLeft: '10%' }}
                  src={mobileLogo}
                />
              </Col>
              <Col xs={2} sm={2}>
                <Button type="text" onClick={showDrawer} className="DrawerNavButton" style={{ background: 'white', color: 'black' }}>
                  <MenuOutlined />
                </Button>
              </Col>
            </Row>
          </Col>

          {/* <Col xs={20} sm={20} md={0}>
            <Image
              style={{ color: "white", marginLeft: 80 }}
              width={200}
              src="https://www.lalune.com/cdn/shop/files/logotype_white_eba7661b-0521-43ab-bce2-b56a327010de.png?v=1648380785&width=240%20240w,%20//www.lalune.com/cdn/shop/files/logotype_white_eba7661b-0521-43ab-bce2-b56a327010de.png?v=1648380785&width=360%20360w,%20//www.lalune.com/cdn/shop/files/logotype_white_eba7661b-0521-43ab-bce2-b56a327010de.png?v=1648380785&width=480%20480w"
            />
          </Col> */}
        </Row>
      </Anchor>
      <Drawer
        // title="Menu"
        placement="left"
        closable={false}
        onClick={onClose}
        onClose={onClose}
        visible={visible}
        style={{ "--color": "white" }}
        width={"80%"}
        headerStyle={{ background: 'black' }}
        bodyStyle={{ background: 'black' }}
      >
        <Menu mode="vertical" defaultSelectedKeys={["1"]} style={{ background: 'black', color: 'white', fontSize: 20 }}>
          <MenuItem />
        </Menu>
      </Drawer>
    </Header>
  );
}

function AppCart() {
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [checkoutDrawerOpen, setCheckoutDrawerOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {

  }, []);
  const onConfirmOrder = (values) => {
    console.log({ values });
    setCartDrawerOpen(false);
    setCheckoutDrawerOpen(false);
    // message.success("Your order has been placed successfully.");
  };

  return (
    <div>

    </div>
  );
}
export default AppHeader;