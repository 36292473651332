export const Circles = () => {
  return (
    <svg
      width="324"
      height="319"
      viewBox="0 0 324 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.241211 159.453C0.241211 137.926 4.51838 117.045 12.9531 97.3857C21.0986 78.398 32.7637 61.3464 47.6191 46.7027C62.4745 32.059 79.7727 20.5602 99.0349 12.5307C118.978 4.21622 140.162 0 161.999 0C183.837 0 205.02 4.21622 224.964 12.5307C244.226 20.5602 261.524 32.059 276.38 46.7027C291.235 61.3464 302.897 78.398 311.046 97.3857C319.48 117.045 323.758 137.926 323.758 159.453C323.758 180.979 319.48 201.861 311.046 221.52C302.897 240.508 291.235 257.559 276.38 272.203C261.524 286.847 244.226 298.342 224.964 306.375C205.02 314.69 183.837 318.906 161.999 318.906C140.162 318.906 118.978 314.69 99.0349 306.375C79.7727 298.346 62.4745 286.847 47.6191 272.203C32.7637 257.559 21.102 240.508 12.9531 221.52C4.51838 201.861 0.241211 180.979 0.241211 159.453ZM322.531 159.453C322.531 138.09 318.287 117.366 309.916 97.8575C301.83 79.0139 290.255 62.0901 275.512 47.5577C260.77 33.0254 243.601 21.6151 224.485 13.6446C204.695 5.3923 183.671 1.20885 161.999 1.20885C140.328 1.20885 119.304 5.3923 99.5134 13.6446C80.3974 21.6151 63.2289 33.0254 48.4865 47.5577C33.7441 62.0901 22.1688 79.0139 14.083 97.8575C5.70815 117.366 1.46421 138.09 1.46421 159.453C1.46421 180.816 5.70815 201.54 14.0797 221.048C22.1654 239.892 33.7407 256.816 48.4832 271.348C63.2256 285.88 80.3941 297.291 99.5101 305.261C119.301 313.513 140.324 317.697 161.996 317.697C183.668 317.697 204.691 313.513 224.482 305.261C243.598 297.291 260.766 285.88 275.509 271.348C290.251 256.816 301.827 239.892 309.912 221.048C318.284 201.54 322.528 180.816 322.528 159.453H322.531Z"
        fill="url(#paint0_linear_120_2323)"
      />
      <path
        d="M6.53516 159.453C6.53516 138.765 10.6462 118.696 18.7518 99.8C26.5817 81.5527 37.7914 65.1628 52.0686 51.0891C66.3458 37.0154 82.9725 25.9654 101.484 18.2472C120.65 10.257 141.008 6.20459 161.996 6.20459C182.983 6.20459 203.341 10.257 222.511 18.2472C241.022 25.9654 257.649 37.0154 271.926 51.0891C286.203 65.1628 297.413 81.5527 305.243 99.8C313.348 118.693 317.459 138.765 317.459 159.453C317.459 180.141 313.348 200.209 305.243 219.102C297.413 237.35 286.203 253.739 271.926 267.813C257.649 281.887 241.022 292.937 222.511 300.655C203.345 308.645 182.986 312.698 161.996 312.698C141.005 312.698 120.65 308.645 101.484 300.655C82.9725 292.937 66.3458 281.887 52.0686 267.813C37.7914 253.739 26.5817 237.35 18.7518 219.102C10.6462 200.209 6.53516 180.141 6.53516 159.453ZM316.236 159.453C316.236 138.925 312.158 119.017 304.116 100.272C296.349 82.1653 285.226 65.9065 271.062 51.9441C256.898 37.9818 240.4 27.017 222.035 19.361C203.019 11.4331 182.82 7.41343 161.999 7.41343C141.178 7.41343 120.979 11.4331 101.962 19.361C83.594 27.0203 67.1002 37.9818 52.936 51.9441C38.7718 65.9065 27.6485 82.1685 19.8818 100.272C11.8392 119.017 7.76148 138.925 7.76148 159.453C7.76148 179.98 11.8392 199.888 19.8818 218.634C27.6518 236.737 38.7718 252.999 52.936 266.961C67.1002 280.924 83.5973 291.888 101.962 299.544C120.979 307.472 141.175 311.492 161.999 311.492C182.823 311.492 203.022 307.472 222.035 299.544C240.4 291.885 256.898 280.924 271.062 266.961C285.226 252.999 296.349 236.737 304.116 218.634C312.158 199.888 316.236 179.977 316.236 159.453Z"
        fill="url(#paint1_linear_120_2323)"
      />
      <path
        d="M12.8301 159.453C12.8301 139.604 16.7749 120.344 24.5516 102.218C32.0657 84.7079 42.8201 68.9831 56.519 55.4794C70.2179 41.9757 86.1701 31.3746 103.933 23.9675C122.325 16.2984 141.86 12.4131 161.996 12.4131C182.132 12.4131 201.67 16.3017 220.059 23.9675C237.822 31.3746 253.774 41.9757 267.473 55.4794C281.172 68.9831 291.926 84.7079 299.44 102.218C307.217 120.348 311.162 139.604 311.162 159.453C311.162 179.303 307.217 198.562 299.44 216.688C291.926 234.198 281.172 249.923 267.473 263.427C253.774 276.931 237.822 287.532 220.059 294.939C201.667 302.608 182.132 306.493 161.996 306.493C141.86 306.493 122.322 302.605 103.933 294.939C86.1701 287.532 70.2179 276.931 56.519 263.427C42.8201 249.923 32.0657 234.198 24.5516 216.688C16.7716 198.559 12.8301 179.303 12.8301 159.453ZM309.942 159.453C309.942 139.764 306.031 120.665 298.317 102.687C290.866 85.3205 280.198 69.7235 266.612 56.3312C253.026 42.9389 237.204 32.4229 219.587 25.0781C201.348 17.4745 181.973 13.6187 161.999 13.6187C142.026 13.6187 122.651 17.4745 104.412 25.0781C86.7949 32.4229 70.9724 42.9389 57.3864 56.3312C43.8005 69.7235 33.1325 85.3205 25.6815 102.687C17.968 120.665 14.0564 139.764 14.0564 159.453C14.0564 179.142 17.968 198.241 25.6815 216.22C33.1325 233.586 43.8005 249.183 57.3864 262.575C70.9724 275.967 86.7949 286.483 104.412 293.828C122.651 301.432 142.026 305.288 161.999 305.288C181.973 305.288 201.348 301.432 219.587 293.828C237.204 286.483 253.026 275.967 266.612 262.575C280.198 249.183 290.866 233.586 298.317 216.22C306.031 198.241 309.942 179.142 309.942 159.453Z"
        fill="url(#paint2_linear_120_2323)"
      />
      <path
        d="M19.125 159.453C19.125 140.439 22.9037 121.995 30.3547 104.632C37.5497 87.8621 47.8522 72.799 60.9728 59.8653C74.0935 46.9317 89.3743 36.7761 106.387 29.6835C124 22.3387 142.711 18.6172 162 18.6172C181.289 18.6172 199.999 22.342 217.613 29.6835C234.625 36.7761 249.906 46.9317 263.027 59.8653C276.147 72.799 286.45 87.8621 293.645 104.632C301.096 121.995 304.871 140.439 304.871 159.453C304.871 178.466 301.093 196.91 293.645 214.273C286.45 231.043 276.147 246.106 263.027 259.04C249.906 271.973 234.625 282.129 217.613 289.222C199.999 296.566 181.289 300.288 162 300.288C142.711 300.288 124 296.563 106.387 289.222C89.3743 282.129 74.0935 271.973 60.9728 259.04C47.8522 246.106 37.5497 231.043 30.3547 214.273C22.9037 196.91 19.125 178.466 19.125 159.453ZM303.648 159.453C303.648 140.602 299.903 122.316 292.518 105.1C285.383 88.4747 275.17 73.5394 262.163 60.7171C249.155 47.8948 234.004 37.8244 217.138 30.7941C199.673 23.5115 181.126 19.8228 162 19.8228C142.874 19.8228 124.326 23.5148 106.865 30.7941C89.9991 37.8277 74.8479 47.8948 61.8402 60.7171C48.8326 73.5394 38.6165 88.4747 31.4846 105.1C24.1001 122.316 20.3546 140.599 20.3546 159.453C20.3546 178.306 24.1001 196.589 31.4846 213.801C38.6199 230.427 48.8326 245.362 61.8402 258.185C74.8479 271.007 89.9991 281.077 106.865 288.108C124.329 295.39 142.877 299.079 162 299.079C181.122 299.079 199.673 295.387 217.138 288.108C234.004 281.074 249.155 271.007 262.163 258.185C275.17 245.362 285.386 230.427 292.518 213.801C299.903 196.586 303.648 178.303 303.648 159.453Z"
        fill="url(#paint3_linear_120_2323)"
      />
      <path
        d="M25.4189 159.454C25.4189 141.278 29.0314 123.647 36.1534 107.048C43.0328 91.0147 52.8799 76.6167 65.4223 64.2531C77.9647 51.8894 92.5709 42.1826 108.835 35.4013C125.675 28.3808 143.561 24.8198 161.999 24.8198C180.437 24.8198 198.324 28.3808 215.163 35.4013C231.428 42.1826 246.034 51.8894 258.576 64.2531C271.119 76.6167 280.966 91.0147 287.845 107.048C294.967 123.647 298.58 141.278 298.58 159.454C298.58 177.629 294.967 195.26 287.845 211.86C280.966 227.893 271.119 242.291 258.576 254.654C246.034 267.018 231.428 276.725 215.163 283.506C198.324 290.527 180.437 294.088 161.999 294.088C143.561 294.088 125.675 290.527 108.835 283.506C92.5709 276.725 77.9647 267.018 65.4223 254.654C52.8799 242.291 43.0328 227.893 36.1534 211.86C29.0314 195.26 25.4189 177.629 25.4189 159.454ZM297.353 159.454C297.353 141.442 293.774 123.968 286.715 107.519C279.899 91.6306 270.138 77.3604 257.709 65.1081C245.276 52.8559 230.803 43.2342 214.685 36.5152C197.998 29.5569 180.271 26.0287 161.999 26.0287C143.727 26.0287 126.001 29.5569 109.314 36.5152C93.1957 43.2342 78.7224 52.8559 66.2897 65.1081C53.8603 77.3636 44.0996 91.6306 37.2834 107.519C30.2245 123.968 26.6453 141.442 26.6453 159.454C26.6453 177.465 30.2245 194.939 37.2834 211.388C44.0996 227.277 53.8603 241.547 66.2897 253.799C78.7224 266.055 93.1957 275.673 109.314 282.392C126.001 289.351 143.727 292.879 161.999 292.879C180.271 292.879 197.998 289.351 214.685 282.392C230.803 275.673 245.276 266.052 257.709 253.799C270.138 241.544 279.899 227.277 286.715 211.388C293.774 194.939 297.353 177.465 297.353 159.454Z"
        fill="url(#paint4_linear_120_2323)"
      />
      <path
        d="M31.7139 159.454C31.7139 142.117 35.1602 125.298 41.9532 109.465C48.5135 94.1728 57.9086 80.4365 69.8727 68.6429C81.8369 56.8493 95.7717 47.588 111.285 41.1212C127.347 34.425 144.409 31.0278 161.996 31.0278C179.584 31.0278 196.646 34.425 212.711 41.1212C228.224 47.588 242.159 56.8493 254.123 68.6429C266.088 80.4365 275.483 94.1728 282.043 109.465C288.836 125.298 292.282 142.117 292.282 159.454C292.282 176.79 288.839 193.609 282.043 209.442C275.483 224.735 266.088 238.471 254.123 250.264C242.159 262.058 228.224 271.319 212.711 277.786C196.649 284.482 179.587 287.88 161.996 287.88C144.406 287.88 127.347 284.486 111.285 277.786C95.7717 271.316 81.8369 262.058 69.8727 250.264C57.9086 238.471 48.5135 224.735 41.9532 209.442C35.1602 193.609 31.7139 176.79 31.7139 159.454ZM291.059 159.454C291.059 142.278 287.646 125.619 280.916 109.934C274.416 94.7854 265.11 81.1802 253.256 69.4947C241.402 57.8124 227.6 48.6363 212.232 42.2318C196.32 35.5979 179.421 32.2334 161.996 32.2334C144.572 32.2334 127.673 35.5979 111.76 42.2318C96.3932 48.6396 82.5879 57.8124 70.7368 69.4947C58.8857 81.1802 49.577 94.7854 43.0798 109.934C36.35 125.619 32.9369 142.278 32.9369 159.454C32.9369 176.63 36.35 193.288 43.0798 208.974C49.5803 224.122 58.8857 237.727 70.7368 249.413C82.5879 261.098 96.3932 270.271 111.76 276.679C127.673 283.313 144.572 286.677 161.996 286.677C179.421 286.677 196.32 283.313 212.232 276.679C227.6 270.271 241.402 261.098 253.256 249.413C265.107 237.727 274.416 224.122 280.916 208.974C287.646 193.288 291.059 176.63 291.059 159.454Z"
        fill="url(#paint5_linear_120_2323)"
      />
      <path
        d="M38.0078 159.453C38.0078 142.955 41.2847 126.945 47.7519 111.879C53.9965 97.3236 62.9364 84.2524 74.3222 73.0288C85.7081 61.8052 98.9683 52.9928 113.734 46.8372C129.021 40.4653 145.259 37.2319 161.996 37.2319C178.732 37.2319 194.974 40.4621 210.258 46.8372C225.024 52.9928 238.284 61.8052 249.67 73.0288C261.055 84.2524 269.995 97.3236 276.243 111.879C282.707 126.949 285.987 142.955 285.987 159.453C285.987 175.951 282.711 191.961 276.243 207.027C269.999 221.582 261.059 234.654 249.67 245.877C238.284 257.101 225.024 265.913 210.258 272.069C194.97 278.444 178.732 281.674 161.996 281.674C145.259 281.674 129.021 278.444 113.734 272.069C98.9683 265.913 85.7081 257.101 74.3222 245.877C62.9364 234.654 53.9965 221.582 47.7519 207.027C41.2847 191.958 38.0078 175.951 38.0078 159.453ZM284.764 159.453C284.764 143.116 281.517 127.266 275.117 112.347C268.932 97.9363 260.082 84.996 248.806 73.8806C237.529 62.7651 224.402 54.0411 209.783 47.9445C194.648 41.6349 178.57 38.4342 161.996 38.4342C145.422 38.4342 129.344 41.6349 114.209 47.9445C99.5898 54.0411 86.4625 62.7651 75.1863 73.8806C63.9101 84.996 55.06 97.9363 48.8752 112.347C42.4744 127.266 39.2275 143.116 39.2275 159.453C39.2275 175.79 42.4744 191.64 48.8752 206.559C55.0567 220.97 63.9101 233.91 75.1863 245.025C86.4625 256.141 99.5898 264.865 114.209 270.962C129.344 277.271 145.422 280.472 161.996 280.472C178.57 280.472 194.648 277.271 209.783 270.962C224.402 264.868 237.529 256.141 248.806 245.025C260.082 233.91 268.932 220.97 275.117 206.559C281.517 191.64 284.764 175.79 284.764 159.453Z"
        fill="url(#paint6_linear_120_2323)"
      />
      <path
        d="M44.3018 159.452C44.3018 143.79 47.4124 128.596 53.5507 114.293C59.4796 100.478 67.9641 88.0683 78.775 77.4147C89.5859 66.7612 102.172 58.3943 116.186 52.5532C130.696 46.5024 146.11 43.436 161.999 43.436C177.888 43.436 193.301 46.5024 207.811 52.5532C221.826 58.3975 234.415 66.7612 245.222 77.4147C256.03 88.0683 264.518 100.478 270.447 114.293C276.585 128.596 279.696 143.79 279.696 159.452C279.696 175.115 276.585 190.309 270.447 204.612C264.518 218.427 256.033 230.837 245.222 241.49C234.415 252.144 221.826 260.511 207.811 266.355C193.301 272.406 177.888 275.472 161.999 275.472C146.11 275.472 130.696 272.406 116.186 266.355C102.172 260.511 89.5826 252.147 78.775 241.49C67.9674 230.837 59.4796 218.427 53.5507 204.612C47.4124 190.309 44.3018 175.115 44.3018 159.452ZM278.469 159.452C278.469 143.954 275.389 128.917 269.317 114.761C263.451 101.09 255.053 88.812 244.355 78.2665C233.657 67.721 221.201 59.4426 207.333 53.6604C192.976 47.6752 177.722 44.6383 161.999 44.6383C146.276 44.6383 131.022 47.6752 116.665 53.6604C102.796 59.4426 90.3403 67.721 79.6424 78.2665C68.9445 88.812 60.5464 101.09 54.6806 114.761C48.6088 128.917 45.5281 143.95 45.5281 159.452C45.5281 174.954 48.6088 189.988 54.6806 204.14C60.5464 217.811 68.9445 230.09 79.6424 240.635C90.3403 251.18 102.796 259.459 116.665 265.241C131.025 271.226 146.276 274.263 161.999 274.263C177.722 274.263 192.976 271.226 207.333 265.241C221.201 259.459 233.657 251.18 244.355 240.635C255.053 230.09 263.451 217.811 269.317 204.14C275.389 189.985 278.469 174.951 278.469 159.452Z"
        fill="url(#paint7_linear_120_2323)"
      />
      <path
        d="M50.5996 159.453C50.5996 144.629 53.5441 130.248 59.3534 116.708C64.9632 103.63 72.9958 91.8888 83.2284 81.802C93.4577 71.7184 105.372 63.8003 118.639 58.2672C132.374 52.5407 146.964 49.6382 162.002 49.6382C177.04 49.6382 191.63 52.5407 205.365 58.2672C218.632 63.7971 230.547 71.7152 240.776 81.802C251.005 91.8855 259.038 103.63 264.651 116.708C270.46 130.248 273.405 144.629 273.405 159.453C273.405 174.277 270.46 188.659 264.651 202.198C259.041 215.276 251.008 227.017 240.776 237.104C230.547 247.188 218.632 255.106 205.365 260.639C191.63 266.365 177.044 269.268 162.002 269.268C146.961 269.268 132.374 266.365 118.639 260.639C105.375 255.109 93.4611 247.191 83.2284 237.104C72.9991 227.021 64.9665 215.276 59.3534 202.198C53.5441 188.659 50.5996 174.277 50.5996 159.453ZM272.175 159.453C272.175 144.79 269.26 130.569 263.518 117.18C257.968 104.246 250.025 92.6324 239.905 82.657C229.785 72.6816 218.004 64.8519 204.884 59.381C191.301 53.7168 176.871 50.847 161.999 50.847C147.127 50.847 132.697 53.7201 119.114 59.381C105.993 64.8519 94.2121 72.6816 84.0925 82.657C73.9728 92.6324 66.03 104.246 60.48 117.18C54.7339 130.569 51.8226 144.793 51.8226 159.453C51.8226 174.113 54.7339 188.338 60.48 201.727C66.03 214.66 73.9728 226.274 84.0925 236.249C94.2121 246.225 105.993 254.054 119.114 259.525C132.697 265.189 147.127 268.059 161.999 268.059C176.871 268.059 191.301 265.186 204.884 259.525C218.004 254.054 229.785 246.225 239.905 236.249C250.025 226.274 257.968 214.66 263.518 201.727C269.264 188.338 272.175 174.113 272.175 159.453Z"
        fill="url(#paint8_linear_120_2323)"
      />
      <path
        d="M56.8955 159.453C56.8955 131.777 67.8294 105.759 87.6798 86.1918C107.53 66.6242 133.924 55.8462 162 55.8462C190.076 55.8462 216.47 66.6242 236.321 86.1918C256.171 105.759 267.105 131.777 267.105 159.453C267.105 187.129 256.171 213.147 236.321 232.714C216.47 252.282 190.073 263.06 162 263.06C133.928 263.06 107.53 252.282 87.6798 232.714C67.8294 213.147 56.8955 187.125 56.8955 159.453ZM265.882 159.453C265.882 132.102 255.078 106.385 235.457 87.0436C215.835 67.702 189.75 57.0518 162 57.0518C134.25 57.0518 108.165 67.702 88.5439 87.0436C68.9228 106.385 58.1185 132.098 58.1185 159.453C58.1185 186.808 68.9228 212.521 88.5439 231.863C108.165 251.204 134.25 261.854 162 261.854C189.75 261.854 215.835 251.204 235.457 231.863C255.078 212.521 265.882 186.808 265.882 159.453Z"
        fill="url(#paint9_linear_120_2323)"
      />
      <path
        d="M63.1895 159.453C63.1895 133.435 73.4686 108.976 92.1293 90.5782C110.793 72.1802 135.606 62.0508 162 62.0508C188.394 62.0508 213.206 72.1835 231.87 90.5782C250.534 108.976 260.81 133.435 260.81 159.453C260.81 185.471 250.531 209.93 231.87 228.328C213.206 246.726 188.394 256.855 162 256.855C135.606 256.855 110.793 246.722 92.1293 228.328C73.4653 209.93 63.1895 185.471 63.1895 159.453ZM259.587 159.453C259.587 133.759 249.438 109.602 231.006 91.4333C212.575 73.2645 188.068 63.2563 162 63.2563C135.931 63.2563 111.428 73.2613 92.9967 91.4333C74.5653 109.602 64.4124 133.759 64.4124 159.453C64.4124 185.147 74.562 209.304 92.9967 227.473C111.428 245.641 135.935 255.646 162 255.646C188.065 255.646 212.571 245.641 231.006 227.473C249.438 209.304 259.587 185.147 259.587 159.453Z"
        fill="url(#paint10_linear_120_2323)"
      />
      <path
        d="M69.4834 159.453C69.4834 135.093 79.1079 112.19 96.5821 94.965C114.056 77.7397 137.29 68.2524 162.003 68.2524C186.715 68.2524 209.949 77.7397 227.423 94.965C244.897 112.19 254.522 135.093 254.522 159.453C254.522 183.813 244.897 206.716 227.423 223.941C209.949 241.166 186.715 250.654 162.003 250.654C137.29 250.654 114.06 241.166 96.5821 223.941C79.1079 206.716 69.4834 183.813 69.4834 159.453ZM253.292 159.453C253.292 135.414 243.797 112.816 226.552 95.82C209.311 78.8241 186.383 69.4613 161.999 69.4613C137.616 69.4613 114.688 78.8208 97.4462 95.82C80.2046 112.816 70.7064 135.417 70.7064 159.453C70.7064 183.489 80.2012 206.09 97.4462 223.086C114.688 240.082 137.616 249.445 161.999 249.445C186.383 249.445 209.311 240.085 226.552 223.086C243.794 206.09 253.292 183.489 253.292 159.453Z"
        fill="url(#paint11_linear_120_2323)"
      />
      <path
        d="M75.7783 159.453C75.7783 136.75 84.7481 115.407 101.033 99.3543C117.317 83.3019 138.969 74.46 162 74.46C185.031 74.46 206.682 83.3019 222.967 99.3543C239.251 115.407 248.221 136.75 248.221 159.453C248.221 182.155 239.251 203.498 222.967 219.551C206.682 235.603 185.031 244.445 162 244.445C138.969 244.445 117.317 235.603 101.033 219.551C84.7481 203.498 75.7783 182.155 75.7783 159.453ZM246.998 159.453C246.998 137.071 238.158 116.032 222.103 100.206C206.048 84.3797 184.705 75.6655 162 75.6655C139.294 75.6655 117.952 84.3797 101.897 100.206C85.8415 116.032 77.0013 137.071 77.0013 159.453C77.0013 181.834 85.8415 202.873 101.897 218.699C117.952 234.525 139.294 243.24 162 243.24C184.705 243.24 206.048 234.525 222.103 218.699C238.158 202.873 246.998 181.834 246.998 159.453Z"
        fill="url(#paint12_linear_120_2323)"
      />
      <path
        d="M82.0723 159.453C82.0723 138.408 90.3873 118.621 105.482 103.741C120.577 88.8616 140.65 80.665 161.999 80.665C183.348 80.665 203.422 88.8616 218.516 103.741C233.614 118.621 241.926 138.408 241.926 159.453C241.926 180.498 233.611 200.285 218.516 215.165C203.418 230.047 183.348 238.241 161.999 238.241C140.65 238.241 120.577 230.044 105.482 215.165C90.3873 200.285 82.0723 180.498 82.0723 159.453ZM240.703 159.453C240.703 138.729 232.518 119.247 217.652 104.596C202.787 89.9427 183.023 81.8739 162.003 81.8739C140.982 81.8739 121.215 89.9427 106.353 104.596C91.4874 119.25 83.3019 138.732 83.3019 159.453C83.3019 180.174 91.4874 199.659 106.353 214.31C121.218 228.963 140.982 237.032 162.003 237.032C183.023 237.032 202.79 228.963 217.652 214.31C232.518 199.656 240.703 180.174 240.703 159.453Z"
        fill="url(#paint13_linear_120_2323)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_120_2323"
          x1="161.175"
          y1="-1.38649e-05"
          x2="161.976"
          y2="318.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_120_2323"
          x1="161.205"
          y1="6.20458"
          x2="161.975"
          y2="312.698"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_120_2323"
          x1="161.236"
          y1="12.4131"
          x2="161.974"
          y2="306.493"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_120_2323"
          x1="161.27"
          y1="18.6172"
          x2="161.978"
          y2="300.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_120_2323"
          x1="161.303"
          y1="24.8198"
          x2="161.98"
          y2="294.088"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_120_2323"
          x1="161.334"
          y1="31.0278"
          x2="161.979"
          y2="287.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_120_2323"
          x1="161.366"
          y1="37.2319"
          x2="161.98"
          y2="281.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_120_2323"
          x1="161.399"
          y1="43.436"
          x2="161.982"
          y2="275.472"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_120_2323"
          x1="161.435"
          y1="49.6382"
          x2="161.986"
          y2="269.268"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_120_2323"
          x1="161.465"
          y1="55.8462"
          x2="161.985"
          y2="263.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_120_2323"
          x1="161.496"
          y1="62.0508"
          x2="161.986"
          y2="256.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_120_2323"
          x1="161.531"
          y1="68.2524"
          x2="161.989"
          y2="250.654"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_120_2323"
          x1="161.56"
          y1="74.46"
          x2="161.987"
          y2="244.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_120_2323"
          x1="161.592"
          y1="80.665"
          x2="161.988"
          y2="238.241"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stop-color="#5BA7FF" />
          <stop offset="0.479167" stop-color="#7FA0F5" />
          <stop offset="1" stop-color="#256CF5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CircleTick = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: "4px" }}
    >
      <path
        d="M13.9612 6.55818C14.54 10.0902 12.1357 13.4281 8.59288 14.0067C5.05005 14.5853 1.73389 12.1705 1.15515 8.6385C0.576414 5.10649 2.98066 1.76858 6.52349 1.18996C7.84525 0.976958 9.1518 1.17478 10.2817 1.706"
        stroke="#F87C56"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5581 2.79002L6.99065 10.1068L3.96293 7.01367"
        stroke="#F87C56"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
