"use client";

export default function MenuCustom({
  className,
  items,
  currentItemKey,
  onClick,
}) {
  if (!items || !items.length) return;
  return (
    <div
      className={`flex flex-row flex-nowrap overflow-x-scroll no-scrollbar gap-5 px-8 lg:px-[10%] border-b ${className}`}
    >
      {items.map((item) => {
        return (
          <div
            className={
              currentItemKey === item.key ? "px-2 py-4 border-b-4 border-b-[#5F95E7] cursor-pointer whitespace-nowrap" : "px-2 py-4 cursor-pointer whitespace-nowrap hover:border-b-4 hover:border-b-[#5F95E7]"
            }
            key={`Menu-${item.key}`}
            onClick={() => onClick({key: item.key})}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
}
